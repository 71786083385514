/* Color helpers */

.bg-black-normal {
    background-color: #000000;
}

.bg-gray-light{
    background-color: $color-gray-light;
}

.txt-color-white{
    color: #ffffff;
}

.txt-color-black{
    color: #000000;
}

.h-color-white{
	h1, .alfa,
	h2, .beta,
	h3, .gamma,
	h4, .delta,
	h5, .epsilon,
	h6, .zeta{
		color: #ffffff;
	}
}

.h-color-purple{
	h1, .alfa,
	h2, .beta,
	h3, .gamma,
	h4, .delta,
	h5, .epsilon,
	h6, .zeta{
		color: $color-blue-normal;
	}
}

.p-color-white{
	p{
		color: #ffffff;
	}
}

.ul-color-white{
	li{
		color: #ffffff!important;
		&:before{
			background-color: #ffffff!important;
		}
	}
}

.ol-color-white{
	li{
		color: #ffffff!important;
		&:before{
			color: #ffffff!important;
		}
	}
}
