/* Buttons helper */

.btn {
	display: inline-block;
	vertical-align: middle;
	position: relative;
	margin: 0;
	padding: 0.6em 1.2rem;
	font-weight: 700;
	line-height: 1.2;
	cursor: pointer;
	text-decoration: none;
	text-transform: uppercase;
	border: 0;
	outline: none !important;
	background: $color-black-normal;
	text-align: center;
	letter-spacing: 0.45px;

	@include transition(background-color, 250ms, ease);
	@include border-radius(25px);
	@include font-size(15px);
	-webkit-appearance: none;
	&,
	&:visited,
	&:active,
	&:focus {
		color: white;
	}

	&:hover {
		@include transition(background-color, 250ms, ease);
		@include bp(medium) {
			cursor: pointer;
			text-decoration: none;
			color: white;
		}
	}
}

.btn-responsive {
	width: 100%;
	padding-right: 0;
	padding-left: 0;
	text-align: center;
}

.btn-flex{
	display: inline-flex;
	align-items: center;
	justify-content: center;
}

.btn-icon {
	position: relative;
	top: 1px;
	margin-left: 0.5rem;
	svg{
		@include font-size(18px);
	}
}

.btn-size-small {
	@include font-size(12px);
}

.btn-size-normal {
	@include font-size(14px);
}

.btn-size-big {
	@include font-size(18px);
}

.btn-size-large {
	@include font-size(20px);
}

.btn-small {
	padding: 0.6em 1rem;
}

.btn-normal {
	padding: 1.5em 1.5rem;
}

.btn-big {
	padding: 2em 2rem;
}

.btn-min-width-small {
	min-width: 120px;
}

.btn-min-width-normal {
	min-width: 250px;
}

.btn-min-width-big {
	min-width: 300px;
}

.btn-orange-fill {
	color: #ffffff;
	border: 2px solid transparent;
	background-color: $color-orange-normal;
	&,
	&:visited,
	&:active,
	&:focus {
		color: #ffffff;
	}
	&:hover {
		color: #ffffff;
		background-color: darken($color-orange-normal, 5%);
	}
	svg{
		fill: #ffffff;
	}
}

.btn-white-outline {
	color: #9dafce;
	border: 2px solid $color-white-light;
	background-color: transparent;
	&,
	&:visited,
	&:active,
	&:focus {
		color: #9dafce;
	}
	&:hover {
		color: #ffffff;
		border: 2px solid $color-white-light;
		background-color: $color-white-light;
		svg{
			fill: #ffffff;
		}
	}
	svg{
		fill: #9dafce;
	}
}
