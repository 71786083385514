/* Breadcrumbs block */

.breadcrumbs-list{
}

.breadcrumb-list-item{
	display: inline-block;
	vertical-align: middle;
	&:last-child .breadcrumb-list-link{
		// color: $color-black-normal;
		@include bp(rev-small){
			margin-right: 0;
		}
		&:after{
			content: none;
		}
	}
}

.breadcrumb-list-link{
	display: inline-block;
	vertical-align: middle;
	line-height: 1.2;
	color: $color-gray-normal;
	@include font-size(14px);
	padding: 0.5rem 1.5rem 0.5rem 0;
	// padding-bottom: 0.8rem;
	position: relative;
	@include bp(small){
		padding: 0.5rem 0 0.5rem 1.5rem;
	}
	&:hover{
		color: $color-blue-normal;
	}
	&:after{
		content: '';
		position: absolute;
		right: 5px;
		top: 11px;
		display: inline-block;
		width: 12px;
		height: 12px;
		vertical-align: middle;
		background-repeat: no-repeat;
		background-position: center;
		-webkit-background-size: cover;
		background-size: cover;
		background-image: url(../images/icons/chevron-right.svg);
		@include bp(small){
			right: -20px;
		}
	}
}