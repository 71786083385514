/* Animate helpers */
@-webkit-keyframes rotate-forever{
	0%{
		transform: rotate(0deg); 
	}
	100%{
		transform: rotate(360deg); 
	} 
}

@keyframes rotate-forever{
	0%{
		transform: rotate(0deg); }
	100%{
		transform: rotate(360deg); 
	}
}

.loading-spinner{
	display: inline-block;
	width: 50px;
	height: 50px;
	animation-name: rotate-forever;
	animation-duration: 0.75s;
	animation-timing-function: linear;
	animation-iteration-count: infinite;
	border: 5px solid $color-blue-normal;
	border-right-color: transparent;
	border-radius: 50%;
}
