.hero-section{
	position: relative;
	padding: 8rem 0.5rem 10rem;
	@include bp(medium){
		padding: 10rem 0 5rem;
	}
	@include bp(excl-medium){
		.btn{
			@include font-size(13px);
		}
	}
}

.hero-content{
	@include bp(rev-small){
		max-width: 300px;
	}
	@include bp(rev-xsmall){
		max-width: 340px;
	}
}

.bg-image-hero-bg{
	position: absolute;
	left: auto;
    right: -170px;
	top: auto;
	bottom: -210px;
	width: 364px;
    height: 260px;
	transform: rotateZ(90deg);
	@include bp(xssmall){
		top: auto;
		right: -190px;
		bottom: -250px;
		width: 433px;
		height: 310px;
	}
	@include bp(xsmall){
		width: 838px;
		height: 600px;
		right: -460px;
    	top: 0;
    	bottom: auto;
	}
	@include bp(small){
		right: -410px;
    	top: -60px;
	}
	@include bp(medium){
		right: -340px;
    	top: -160px;
		transform: rotateZ(0);
	}
	@include bp(large){
    	top: -160px;
    	right: -280px;
	}
}

.hero-img{
	position: absolute;
	bottom: -140px;
	right: -80px;
	width: 200px;
	@include bp(xssmall){
		bottom: -180px;
		right: -100px;
		width: 250px;
	}
	@include bp(xsmall){
		bottom: auto;
		top: 110px;
		right: 0px;
		width: 240px;
	}
	@include bp(small){
		position: relative;
		margin-left: auto;
		top: 20px;
		right: -30px;
		width: 100%;
	}
	@include bp(medium){
		top: 0;
		right: -30px;
		width: 90%;
	}
	@include bp(large){
		width: auto;
		top: 0;
		right: -50px;
	}
	@include bp(xlarge){
		top: 0;
		right: -30px;
	}
}