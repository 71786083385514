/* Blog block */
.blog-item{
	margin-bottom: 2rem;
}

.blog-content{
	display: block;
	position: relative;
	box-shadow: 0 0 51px rgba(10, 34, 51, 0.07);
	border-radius: 5px;
	background-color: #ffffff;
	@include bp(xsmall){
		display: flex;
		// align-items: center;
	}
	&:hover{
		.blog-title{
			color: $color-blue-normal;
		}

		img{
			transform: scale(1.05, 1.05);
		}

	}

}

.blog-image-block{
	display: block;
	position: relative;
	overflow: hidden;
	background-color: #2cd5ff;
	border-radius: 5px 5px 0 0;
	@include bp(xsmall){
		border-radius: 5px 0 0 5px;
		display: flex;
		width: 230px;
		max-width: 230px;
		flex: 0 0 230px;
	}
	@include bp(small){
		width: 270px;
		max-width: 270px;
		flex: 0 0 270px;
	}
	@include bp(excl-large){
		width: 200px;
		max-width: 200px;
		flex: 0 0 200px;
	}
	img{
		transition: transform 400ms ease-out;
	}

}

.blog-text-block{
	padding: 1.5rem 1.2rem 1.5rem 2rem;
	@include bp(xsmall){
		align-self: center;
		width: calc(100% - 230px);
		max-width: calc(100% - 230px);
		flex: 0 0 calc(100% - 230px);
	}
	@include bp(small){
		width: calc(100% - 270px);
		max-width: calc(100% - 270px);
		flex: 0 0 calc(100% - 270px);
	}
	@include bp(excl-large){
		padding: 1.5rem 1rem 1.5rem 1.5rem;
		width: calc(100% - 200px);
		max-width: calc(100% - 200px);
		flex: 0 0 calc(100% - 200px);
	}
}

.blog-date{
	position: absolute;
	top: 20px;
	left: -12px;
	border-radius: 20px;
	background-color: $color-blue-normal;
	display: flex;
	align-items: center;
	justify-content: center;
	height: 35px;
	min-width: 105px;
	color: #ffffff;
	@include font-size(15px);
	font-weight: 300;
	line-height: 1.2;
	letter-spacing: 0.75px;
	z-index: 2;
}
