.ico-svg {
  display: inline-block;
  width: 1em;
  height: 1em;
  stroke-width: 0;
  stroke: currentColor;
  fill: currentColor;
}

/* Animate helpers */
@-webkit-keyframes rotate-forever {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes rotate-forever {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.loading-spinner {
  display: inline-block;
  width: 50px;
  height: 50px;
  animation-name: rotate-forever;
  animation-duration: 0.75s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  border: 5px solid #2c81d0;
  border-right-color: transparent;
  border-radius: 50%;
}

/* Typography */
.typography p,
.typography li,
.typography td,
.typography th {
  line-height: 1.5;
}

.typography p,
.typography ul, .typography ol,
.typography h1, .typography h2, .typography h3, .typography h4, .typography h5, .typography h6,
.typography table,
.typography fieldset,
.typography blockquote,
.typography iframe {
  margin-bottom: 1rem;
}

.typography h1 {
  display: block;
  margin-top: 3rem;
  margin-bottom: 4rem;
  font-family: "jaf-domus", Arial, sans-serif;
  font-weight: 300;
  color: #2c81d0;
  text-align: center;
  letter-spacing: 0.35px;
  text-transform: uppercase;
  font-size: 24px;
  font-size: 1.5rem;
}

@media (min-width: 992px) {
  .typography h1 {
    margin-top: 4rem;
    margin-bottom: 6rem;
    font-size: 30px;
    font-size: 1.875rem;
  }
}

.typography h1:after {
  content: '';
  position: relative;
  display: block;
  width: 40px;
  height: 2px;
  box-shadow: 0 0 91px rgba(0, 0, 0, 0.05);
  border-radius: 1px;
  background-color: #dce6fa;
  margin: 15px auto 0;
}

.typography h2 {
  display: block;
  margin-bottom: 2rem;
  font-family: "jaf-domus", Arial, sans-serif;
  font-weight: 500;
  color: #424242;
  letter-spacing: 0.2px;
  font-size: 22px;
  font-size: 1.375rem;
}

@media (min-width: 992px) {
  .typography h2 {
    margin-bottom: 3rem;
    font-size: 26px;
    font-size: 1.625rem;
  }
}

.typography h3 {
  display: block;
  margin-top: 3rem;
  margin-bottom: 2rem;
  font-family: "jaf-domus", Arial, sans-serif;
  color: #424242;
  font-weight: 500;
  line-height: 1.2;
  text-transform: uppercase;
  letter-spacing: 0.2px;
  font-size: 16px;
  font-size: 1rem;
}

@media (min-width: 771px) {
  .typography h3 {
    font-size: 18px;
    font-size: 1.125rem;
  }
}

.typography h5 {
  display: block;
  margin-top: 1rem;
  margin-bottom: 1.5rem;
  font-family: "jaf-domus", Arial, sans-serif;
  font-weight: 500;
  color: #424242;
  letter-spacing: 0.1px;
  font-size: 14px;
  font-size: 0.875rem;
}

@media (min-width: 771px) {
  .typography h5 {
    font-size: 16px;
    font-size: 1rem;
  }
}

.typography h6 {
  display: block;
  margin-top: 1rem;
  margin-bottom: 1.5rem;
  font-family: "jaf-domus", Arial, sans-serif;
  font-weight: 500;
  color: #424242;
  letter-spacing: 0.1px;
  font-size: 14px;
  font-size: 0.875rem;
}

@media (min-width: 771px) {
  .typography h6 {
    font-size: 16px;
    font-size: 1rem;
  }
}

.typography a {
  text-decoration: underline;
  color: #2c81d0;
}

.typography a:hover {
  text-decoration: underline;
}

.typography p {
  display: block;
  margin-top: 0;
  margin-bottom: 1.5rem;
  color: #8f8f8f;
  font-family: "jaf-domus", Arial, sans-serif;
  font-weight: 400;
  line-height: 1.6;
  font-size: 16px;
  font-size: 1rem;
}

@media (min-width: 992px) {
  .typography p {
    font-size: 18px;
    font-size: 1.125rem;
  }
}

.typography strong {
  font-weight: 500;
  color: #2c81d0;
}

.typography img {
  display: block;
  height: auto;
  max-width: 100%;
  margin-bottom: 2rem;
  width: 100%;
}

.typography ul,
.typography ol {
  margin-bottom: 2rem;
  padding-left: 0;
  list-style: none;
}

.typography ul li,
.typography ol li {
  position: relative;
  margin-bottom: 5px;
  padding-left: 25px;
  font-family: "jaf-domus", Arial, sans-serif;
  font-weight: 300;
  line-height: 1.8;
  color: #424242;
  font-size: 15px;
  font-size: 0.9375rem;
}

.typography ul li {
  padding-left: 30px;
}

.typography ul li:before {
  content: "";
  position: absolute;
  top: 7px;
  left: 3px;
  display: block;
  width: 15px;
  height: 12px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  background-image: url("../images/icons/chevron-right.svg");
}

.typography ol {
  list-style-position: inside;
  counter-reset: item;
}

.typography ol li {
  padding-left: 20px;
}

.typography ol li:before {
  position: absolute;
  top: 2px;
  left: 0;
  display: inline-block;
  line-height: 1.4;
  content: counter(item) ".";
  font-family: "jaf-domus", Arial, sans-serif;
  counter-increment: item;
  color: #2c81d0;
  font-weight: 600;
  font-size: 16px;
  font-size: 1rem;
}

.typography table {
  margin-bottom: 2rem;
}

.typography figure {
  position: relative;
  margin: 0;
  margin-bottom: 2em;
  box-shadow: 0 4px 19px rgba(0, 0, 0, 0.07);
  border-radius: 5px;
  background-color: #ffffff;
  overflow: hidden;
}

@media (min-width: 771px) {
  .typography figure {
    position: sticky;
    top: 100px;
    margin-bottom: 60px;
  }
}

.typography figure img {
  margin: 0;
}

.typography .js-gallery-item {
  text-decoration: none !important;
}

.typography figcaption {
  font-family: "jaf-domus", Arial, sans-serif;
  color: #858585;
  padding: 1.3rem 1.5rem;
  font-size: 12px;
  font-size: 0.75rem;
  border-bottom: 3px solid #2c81d0;
  font-weight: 500;
  line-height: 1.6;
  background-color: #ffffff;
  letter-spacing: 0.36px;
}

.typography figcaption strong {
  display: block;
  color: #383838;
  font-size: 15px;
  font-size: 0.9375rem;
  letter-spacing: 0.4px;
  font-weight: 500;
}

/* Buttons helper */
.btn {
  display: inline-block;
  vertical-align: middle;
  position: relative;
  margin: 0;
  padding: 0.6em 1.2rem;
  font-weight: 700;
  line-height: 1.2;
  cursor: pointer;
  text-decoration: none;
  text-transform: uppercase;
  border: 0;
  outline: none !important;
  background: #424242;
  text-align: center;
  letter-spacing: 0.45px;
  transition: background-color 250ms ease;
  border-radius: 25px;
  font-size: 15px;
  font-size: 0.9375rem;
  -webkit-appearance: none;
}

.btn, .btn:visited, .btn:active, .btn:focus {
  color: white;
}

.btn:hover {
  transition: background-color 250ms ease;
}

@media (min-width: 992px) {
  .btn:hover {
    cursor: pointer;
    text-decoration: none;
    color: white;
  }
}

.btn-responsive {
  width: 100%;
  padding-right: 0;
  padding-left: 0;
  text-align: center;
}

.btn-flex {
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.btn-icon {
  position: relative;
  top: 1px;
  margin-left: 0.5rem;
}

.btn-icon svg {
  font-size: 18px;
  font-size: 1.125rem;
}

.btn-size-small {
  font-size: 12px;
  font-size: 0.75rem;
}

.btn-size-normal {
  font-size: 14px;
  font-size: 0.875rem;
}

.btn-size-big {
  font-size: 18px;
  font-size: 1.125rem;
}

.btn-size-large {
  font-size: 20px;
  font-size: 1.25rem;
}

.btn-small {
  padding: 0.6em 1rem;
}

.btn-normal {
  padding: 1.5em 1.5rem;
}

.btn-big {
  padding: 2em 2rem;
}

.btn-min-width-small {
  min-width: 120px;
}

.btn-min-width-normal {
  min-width: 250px;
}

.btn-min-width-big {
  min-width: 300px;
}

.btn-orange-fill {
  color: #ffffff;
  border: 2px solid transparent;
  background-color: #ff7a72;
}

.btn-orange-fill, .btn-orange-fill:visited, .btn-orange-fill:active, .btn-orange-fill:focus {
  color: #ffffff;
}

.btn-orange-fill:hover {
  color: #ffffff;
  background-color: #ff6259;
}

.btn-orange-fill svg {
  fill: #ffffff;
}

.btn-white-outline {
  color: #9dafce;
  border: 2px solid #cfdbef;
  background-color: transparent;
}

.btn-white-outline, .btn-white-outline:visited, .btn-white-outline:active, .btn-white-outline:focus {
  color: #9dafce;
}

.btn-white-outline:hover {
  color: #ffffff;
  border: 2px solid #cfdbef;
  background-color: #cfdbef;
}

.btn-white-outline:hover svg {
  fill: #ffffff;
}

.btn-white-outline svg {
  fill: #9dafce;
}

/* Color helpers */
.bg-black-normal {
  background-color: #000000;
}

.bg-gray-light {
  background-color: #f5f7ff;
}

.txt-color-white {
  color: #ffffff;
}

.txt-color-black {
  color: #000000;
}

.h-color-white h1, .h-color-white .alfa,
.h-color-white h2, .h-color-white .beta,
.h-color-white h3, .h-color-white .gamma,
.h-color-white h4, .h-color-white .delta,
.h-color-white h5, .h-color-white .epsilon,
.h-color-white h6, .h-color-white .zeta {
  color: #ffffff;
}

.h-color-purple h1, .h-color-purple .alfa,
.h-color-purple h2, .h-color-purple .beta,
.h-color-purple h3, .h-color-purple .gamma,
.h-color-purple h4, .h-color-purple .delta,
.h-color-purple h5, .h-color-purple .epsilon,
.h-color-purple h6, .h-color-purple .zeta {
  color: #2c81d0;
}

.p-color-white p {
  color: #ffffff;
}

.ul-color-white li {
  color: #ffffff !important;
}

.ul-color-white li:before {
  background-color: #ffffff !important;
}

.ol-color-white li {
  color: #ffffff !important;
}

.ol-color-white li:before {
  color: #ffffff !important;
}

/* Gutter helpers */
.no-gutter {
  margin: 0;
}

.no-gutter > [class*="col-"] {
  padding-right: 0;
  padding-left: 0;
}

.gutter-10.row {
  margin-right: -5px;
  margin-left: -5px;
}

.gutter-10 > [class*="col-"],
.gutter-10 > [class*=" col-"] {
  padding-right: 5px;
  padding-left: 5px;
}

.gutter-20.row {
  margin-right: -10px;
  margin-left: -10px;
}

.gutter-20 > [class*="col-"],
.gutter-20 > [class*=" col-"] {
  padding-right: 10px;
  padding-left: 10px;
}

.gutter-30.row {
  margin-right: -15px;
  margin-left: -15px;
}

.gutter-30 > [class*="col-"],
.gutter-30 > [class*=" col-"] {
  padding-right: 15px;
  padding-left: 15px;
}

.gutter-40.row {
  margin-right: -20px;
  margin-left: -20px;
}

.gutter-40 > [class*="col-"],
.gutter-40 > [class*=" col-"] {
  padding-right: 20px;
  padding-left: 20px;
}

.gutter-50.row {
  margin-right: -25px;
  margin-left: -25px;
}

.gutter-50 > [class*="col-"],
.gutter-50 > [class*=" col-"] {
  padding-right: 25px;
  padding-left: 25px;
}

.gutter-60.row {
  margin-right: -30px;
  margin-left: -30px;
}

.gutter-60 > [class*="col-"],
.gutter-60 > [class*=" col-"] {
  padding-right: 30px;
  padding-left: 30px;
}

@media (min-width: 576px) {
  .gutter-xs-10.row {
    margin-right: -5px;
    margin-left: -5px;
  }
  .gutter-xs-10 > [class*="col-"],
  .gutter-xs-10 > [class*=" col-"] {
    padding-right: 5px;
    padding-left: 5px;
  }
  .gutter-xs-20.row {
    margin-right: -10px;
    margin-left: -10px;
  }
  .gutter-xs-20 > [class*="col-"],
  .gutter-xs-20 > [class*=" col-"] {
    padding-right: 10px;
    padding-left: 10px;
  }
  .gutter-xs-30.row {
    margin-right: -15px;
    margin-left: -15px;
  }
  .gutter-xs-30 > [class*="col-"],
  .gutter-xs-30 > [class*=" col-"] {
    padding-right: 15px;
    padding-left: 15px;
  }
  .gutter-xs-40.row {
    margin-right: -20px;
    margin-left: -20px;
  }
  .gutter-xs-40 > [class*="col-"],
  .gutter-xs-40 > [class*=" col-"] {
    padding-right: 20px;
    padding-left: 20px;
  }
  .gutter-xs-50.row {
    margin-right: -25px;
    margin-left: -25px;
  }
  .gutter-xs-50 > [class*="col-"],
  .gutter-xs-50 > [class*=" col-"] {
    padding-right: 25px;
    padding-left: 25px;
  }
  .gutter-xs-60.row {
    margin-right: -30px;
    margin-left: -30px;
  }
  .gutter-xs-60 > [class*="col-"],
  .gutter-xs-60 > [class*=" col-"] {
    padding-right: 30px;
    padding-left: 30px;
  }
  .gutter-xs-100.row {
    margin-right: -50px;
    margin-left: -50px;
  }
  .gutter-xs-100 > [class*="col-"],
  .gutter-xs-100 > [class*=" col-"] {
    padding-right: 50px;
    padding-left: 50px;
  }
}

@media (min-width: 771px) {
  .gutter-sm-10.row {
    margin-right: -5px;
    margin-left: -5px;
  }
  .gutter-sm-10 > [class*="col-"],
  .gutter-sm-10 > [class*=" col-"] {
    padding-right: 5px;
    padding-left: 5px;
  }
  .gutter-sm-20.row {
    margin-right: -10px;
    margin-left: -10px;
  }
  .gutter-sm-20 > [class*="col-"],
  .gutter-sm-20 > [class*=" col-"] {
    padding-right: 10px;
    padding-left: 10px;
  }
  .gutter-sm-30.row {
    margin-right: -15px;
    margin-left: -15px;
  }
  .gutter-sm-30 > [class*="col-"],
  .gutter-sm-30 > [class*=" col-"] {
    padding-right: 15px;
    padding-left: 15px;
  }
  .gutter-sm-40.row {
    margin-right: -20px;
    margin-left: -20px;
  }
  .gutter-sm-40 > [class*="col-"],
  .gutter-sm-40 > [class*=" col-"] {
    padding-right: 20px;
    padding-left: 20px;
  }
  .gutter-sm-50.row {
    margin-right: -25px;
    margin-left: -25px;
  }
  .gutter-sm-50 > [class*="col-"],
  .gutter-sm-50 > [class*=" col-"] {
    padding-right: 25px;
    padding-left: 25px;
  }
  .gutter-sm-60.row {
    margin-right: -30px;
    margin-left: -30px;
  }
  .gutter-sm-60 > [class*="col-"],
  .gutter-sm-60 > [class*=" col-"] {
    padding-right: 30px;
    padding-left: 30px;
  }
  .gutter-sm-100.row {
    margin-right: -50px;
    margin-left: -50px;
  }
  .gutter-sm-100 > [class*="col-"],
  .gutter-sm-100 > [class*=" col-"] {
    padding-right: 50px;
    padding-left: 50px;
  }
}

@media (min-width: 992px) {
  .gutter-md-10.row {
    margin-right: -5px;
    margin-left: -5px;
  }
  .gutter-md-10 > [class*="col-"],
  .gutter-md-10 > [class*=" col-"] {
    padding-right: 5px;
    padding-left: 5px;
  }
  .gutter-md-20.row {
    margin-right: -10px;
    margin-left: -10px;
  }
  .gutter-md-20 > [class*="col-"],
  .gutter-md-20 > [class*=" col-"] {
    padding-right: 10px;
    padding-left: 10px;
  }
  .gutter-md-30.row {
    margin-right: -15px;
    margin-left: -15px;
  }
  .gutter-md-30 > [class*="col-"],
  .gutter-md-30 > [class*=" col-"] {
    padding-right: 15px;
    padding-left: 15px;
  }
  .gutter-md-40.row {
    margin-right: -20px;
    margin-left: -20px;
  }
  .gutter-md-40 > [class*="col-"],
  .gutter-md-40 > [class*=" col-"] {
    padding-right: 20px;
    padding-left: 20px;
  }
  .gutter-md-50.row {
    margin-right: -25px;
    margin-left: -25px;
  }
  .gutter-md-50 > [class*="col-"],
  .gutter-md-50 > [class*=" col-"] {
    padding-right: 25px;
    padding-left: 25px;
  }
  .gutter-md-60.row {
    margin-right: -30px;
    margin-left: -30px;
  }
  .gutter-md-60 > [class*="col-"],
  .gutter-md-60 > [class*=" col-"] {
    padding-right: 30px;
    padding-left: 30px;
  }
  .gutter-md-100.row {
    margin-right: -50px;
    margin-left: -50px;
  }
  .gutter-md-100 > [class*="col-"],
  .gutter-md-100 > [class*=" col-"] {
    padding-right: 50px;
    padding-left: 50px;
  }
}

@media (min-width: 1200px) {
  .gutter-lg-10.row {
    margin-right: -5px;
    margin-left: -5px;
  }
  .gutter-lg-10 > [class*="col-"],
  .gutter-lg-10 > [class*=" col-"] {
    padding-right: 5px;
    padding-left: 5px;
  }
  .gutter-lg-20.row {
    margin-right: -10px;
    margin-left: -10px;
  }
  .gutter-lg-20 > [class*="col-"],
  .gutter-lg-20 > [class*=" col-"] {
    padding-right: 10px;
    padding-left: 10px;
  }
  .gutter-lg-30.row {
    margin-right: -15px;
    margin-left: -15px;
  }
  .gutter-lg-30 > [class*="col-"],
  .gutter-lg-30 > [class*=" col-"] {
    padding-right: 15px;
    padding-left: 15px;
  }
  .gutter-lg-40.row {
    margin-right: -20px;
    margin-left: -20px;
  }
  .gutter-lg-40 > [class*="col-"],
  .gutter-lg-40 > [class*=" col-"] {
    padding-right: 20px;
    padding-left: 20px;
  }
  .gutter-lg-50.row {
    margin-right: -25px;
    margin-left: -25px;
  }
  .gutter-lg-50 > [class*="col-"],
  .gutter-lg-50 > [class*=" col-"] {
    padding-right: 25px;
    padding-left: 25px;
  }
  .gutter-lg-60.row {
    margin-right: -30px;
    margin-left: -30px;
  }
  .gutter-lg-60 > [class*="col-"],
  .gutter-lg-60 > [class*=" col-"] {
    padding-right: 30px;
    padding-left: 30px;
  }
  .gutter-lg-100.row {
    margin-right: -50px;
    margin-left: -50px;
  }
  .gutter-lg-100 > [class*="col-"],
  .gutter-lg-100 > [class*=" col-"] {
    padding-right: 50px;
    padding-left: 50px;
  }
}

/* Hidden class */
.invisible {
  visibility: hidden;
}

.visible {
  visibility: visible;
}

.hide {
  display: none;
}

.show {
  display: block;
}

.overflow {
  overflow: auto;
}

.overflow-hidden {
  overflow: hidden;
}

.overflow-x-hidden {
  overflow-x: hidden;
}

.overflow-visible {
  overflow: visible;
}

/* Icon helpers */
.ico-size-16 svg {
  font-size: 16px;
  font-size: 1rem;
}

.ico-size-18 svg {
  font-size: 18px;
  font-size: 1.125rem;
}

.ico-size-20 svg {
  font-size: 20px;
  font-size: 1.25rem;
}

.ico-size-22 svg {
  font-size: 22px;
  font-size: 1.375rem;
}

.ico-size-24 svg {
  font-size: 24px;
  font-size: 1.5rem;
}

.ico-size-26 svg {
  font-size: 26px;
  font-size: 1.625rem;
}

.ico-size-60 svg {
  font-size: 60px;
  font-size: 3.75rem;
}

.ico-color-white svg {
  fill: #ffffff;
}

.ico-color-gray svg {
  color: #9dafce;
}

/* Image helpers */
.img {
  display: block;
}

.img-full {
  width: 100%;
}

.img-responsive {
  display: block;
  max-width: 100%;
  height: auto;
}

.img-cover {
  height: 100%;
  object-fit: cover;
}

.img-contain {
  height: 100%;
  object-fit: contain;
}

.img-filter-hover {
  position: relative;
}

.img-filter-hover .img-filter-classic {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  opacity: 0;
  visibility: hidden;
  transition: all 250ms ease-out;
}

.img-filter-hover .img-filter-gray {
  transition: all 250ms ease-out;
}

.img-filter-hover:hover .img-filter-classic {
  opacity: 1;
  visibility: visible;
}

.img-filter-hover:hover .img-filter-gray {
  opacity: 0;
  visibility: hidden;
}

.img-hover {
  display: block;
  overflow: hidden;
}

.img-hover:hover > img {
  transform: scale(1.05, 1.05);
}

.img-hover img {
  transition: transform 400ms ease-out;
}

.bg-image-hover {
  display: block;
  overflow: hidden;
}

.bg-image-hover:hover > .bg-image {
  transform: scale(1.05, 1.05);
}

.bg-image-hover .bg-image {
  transition: transform 400ms ease-out;
}

.img-flex-cover {
  display: flex;
}

.img-flex-cover img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.img-flex-contain {
  display: flex;
}

.img-flex-contain img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.img-flex-blog {
  height: 230px;
}

@media (min-width: 576px) {
  .img-flex-blog {
    height: 230px;
  }
}

.bg-image {
  display: block;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.bg-image-pattern-1 {
  position: absolute;
  top: 0;
  left: -550px;
  width: 912px;
  height: 1169px;
  transition: left 450ms ease-out;
  opacity: 0.6;
}

@media (min-width: 771px) {
  .bg-image-pattern-1 {
    opacity: 1;
    left: -400px;
  }
}

.bg-image-pattern-2 {
  position: absolute;
  top: auto;
  bottom: -600px;
  left: auto;
  right: -700px;
  width: 796px;
  height: 819px;
  transition: right 450ms ease-out;
  opacity: 0.6;
}

@media (min-width: 771px) {
  .bg-image-pattern-2 {
    opacity: 1;
    bottom: -380px;
    right: -550px;
  }
}

@media (min-width: 992px) {
  .bg-image-pattern-2 {
    right: -400px;
  }
}

/* Margin helpers */
.margin-0 {
  margin: 0 !important;
}

@media (min-width: 576px) {
  .margin-xs-0 {
    margin: 0 !important;
  }
}

@media (min-width: 771px) {
  .margin-sm-0 {
    margin: 0 !important;
  }
}

@media (min-width: 992px) {
  .margin-md-0 {
    margin: 0 !important;
  }
}

@media (min-width: 1200px) {
  .margin-lg-0 {
    margin: 0 !important;
  }
}

@media (min-width: 1440px) {
  .margin-xl-0 {
    margin: 0 !important;
  }
}

.margin-bottom-01 {
  margin-bottom: 0.1rem;
}

.margin-bottom-02 {
  margin-bottom: 0.2rem;
}

.margin-bottom-03 {
  margin-bottom: 0.3rem;
}

.margin-bottom-04 {
  margin-bottom: 0.4rem;
}

.margin-bottom-05 {
  margin-bottom: 0.5rem;
}

.margin-bottom-06 {
  margin-bottom: 0.6rem;
}

.margin-bottom-07 {
  margin-bottom: 0.7rem;
}

.margin-bottom-08 {
  margin-bottom: 0.8rem;
}

.margin-bottom-1 {
  margin-bottom: 1rem;
}

.margin-bottom-1-5 {
  margin-bottom: 1.5rem;
}

.margin-bottom-2 {
  margin-bottom: 2rem;
}

.margin-bottom-3 {
  margin-bottom: 3rem;
}

.margin-bottom-3-5 {
  margin-bottom: 3.5rem;
}

.margin-bottom-4 {
  margin-bottom: 4rem;
}

.margin-bottom-5 {
  margin-bottom: 5rem;
}

.margin-bottom-6 {
  margin-bottom: 6rem;
}

.margin-bottom-7 {
  margin-bottom: 7rem;
}

.margin-bottom-8 {
  margin-bottom: 8rem;
}

.margin-bottom-9 {
  margin-bottom: 9rem;
}

.margin-bottom-10 {
  margin-bottom: 10rem;
}

@media (min-width: 576px) {
  .margin-bottom-xs-1 {
    margin-bottom: 1rem;
  }
  .margin-bottom-xs-2 {
    margin-bottom: 2rem;
  }
  .margin-bottom-xs-3 {
    margin-bottom: 3rem;
  }
  .margin-bottom-xs-4 {
    margin-bottom: 4rem;
  }
  .margin-bottom-xs-5 {
    margin-bottom: 5rem;
  }
  .margin-bottom-xs-6 {
    margin-bottom: 6rem;
  }
}

@media (min-width: 771px) {
  .margin-bottom-sm-1 {
    margin-bottom: 1rem;
  }
  .margin-bottom-sm-2 {
    margin-bottom: 2rem;
  }
  .margin-bottom-sm-3 {
    margin-bottom: 3rem;
  }
  .margin-bottom-sm-4 {
    margin-bottom: 4rem;
  }
  .margin-bottom-sm-5 {
    margin-bottom: 5rem;
  }
  .margin-bottom-sm-6 {
    margin-bottom: 6rem;
  }
}

@media (min-width: 992px) {
  .margin-bottom-md-1 {
    margin-bottom: 1rem;
  }
  .margin-bottom-md-2 {
    margin-bottom: 2rem;
  }
  .margin-bottom-md-3 {
    margin-bottom: 3rem;
  }
  .margin-bottom-md-4 {
    margin-bottom: 4rem;
  }
  .margin-bottom-md-5 {
    margin-bottom: 5rem;
  }
  .margin-bottom-md-6 {
    margin-bottom: 6rem;
  }
}

@media (min-width: 1200px) {
  .margin-bottom-lg-1 {
    margin-bottom: 1rem;
  }
  .margin-bottom-lg-2 {
    margin-bottom: 2rem;
  }
  .margin-bottom-lg-3 {
    margin-bottom: 3rem;
  }
  .margin-bottom-lg-4 {
    margin-bottom: 4rem;
  }
  .margin-bottom-lg-5 {
    margin-bottom: 5rem;
  }
  .margin-bottom-lg-6 {
    margin-bottom: 6rem;
  }
}

@media (min-width: 1440px) {
  .margin-bottom-xl-1 {
    margin-bottom: 1rem;
  }
  .margin-bottom-xl-2 {
    margin-bottom: 2rem;
  }
  .margin-bottom-xl-3 {
    margin-bottom: 3rem;
  }
  .margin-bottom-xl-4 {
    margin-bottom: 4rem;
  }
  .margin-bottom-xl-5 {
    margin-bottom: 5rem;
  }
  .margin-bottom-xl-6 {
    margin-bottom: 6rem;
  }
}

.margin-top-01 {
  margin-top: 0.1rem;
}

.margin-top-02 {
  margin-top: 0.2rem;
}

.margin-top-03 {
  margin-top: 0.3rem;
}

.margin-top-04 {
  margin-top: 0.4rem;
}

.margin-top-05 {
  margin-top: 0.5rem;
}

.margin-top-08 {
  margin-top: 0.8rem;
}

.margin-top-1 {
  margin-top: 1rem;
}

.margin-top-1-5 {
  margin-top: 1.5rem;
}

.margin-top-2 {
  margin-top: 2rem;
}

.margin-top-3 {
  margin-top: 3rem;
}

.margin-top-4 {
  margin-top: 4rem;
}

.margin-top-5 {
  margin-top: 5rem;
}

.margin-top-6 {
  margin-top: 6rem;
}

.margin-top-7 {
  margin-top: 7rem;
}

.margin-top-8 {
  margin-top: 8rem;
}

.margin-top-9 {
  margin-top: 9rem;
}

.margin-top-10 {
  margin-top: 10rem;
}

@media (min-width: 576px) {
  .margin-top-xs-1 {
    margin-top: 1rem;
  }
  .margin-top-xs-2 {
    margin-top: 2rem;
  }
  .margin-top-xs-3 {
    margin-top: 3rem;
  }
  .margin-top-xs-4 {
    margin-top: 4rem;
  }
  .margin-top-xs-5 {
    margin-top: 5rem;
  }
  .margin-top-xs-6 {
    margin-top: 6rem;
  }
}

@media (min-width: 771px) {
  .margin-top-sm-1 {
    margin-top: 1rem;
  }
  .margin-top-sm-2 {
    margin-top: 2rem;
  }
  .margin-top-sm-3 {
    margin-top: 3rem;
  }
  .margin-top-sm-4 {
    margin-top: 4rem;
  }
  .margin-top-sm-5 {
    margin-top: 5rem;
  }
  .margin-top-sm-6 {
    margin-top: 6rem;
  }
}

@media (min-width: 992px) {
  .margin-top-md-1 {
    margin-top: 1rem;
  }
  .margin-top-md-2 {
    margin-top: 2rem;
  }
  .margin-top-md-3 {
    margin-top: 3rem;
  }
  .margin-top-md-4 {
    margin-top: 4rem;
  }
  .margin-top-md-5 {
    margin-top: 5rem;
  }
  .margin-top-md-6 {
    margin-top: 6rem;
  }
}

@media (min-width: 1200px) {
  .margin-top-lg-1 {
    margin-top: 1rem;
  }
  .margin-top-lg-2 {
    margin-top: 2rem;
  }
  .margin-top-lg-3 {
    margin-top: 3rem;
  }
  .margin-top-lg-4 {
    margin-top: 4rem;
  }
  .margin-top-lg-5 {
    margin-top: 5rem;
  }
  .margin-top-lg-6 {
    margin-top: 6rem;
  }
}

@media (min-width: 1440px) {
  .margin-top-xl-1 {
    margin-top: 1rem;
  }
  .margin-top-xl-2 {
    margin-top: 2rem;
  }
  .margin-top-xl-3 {
    margin-top: 3rem;
  }
  .margin-top-xl-4 {
    margin-top: 4rem;
  }
  .margin-top-xl-5 {
    margin-top: 5rem;
  }
  .margin-top-xl-6 {
    margin-top: 6rem;
  }
}

.margin-left-01 {
  margin-left: 0.1rem;
}

.margin-left-02 {
  margin-left: 0.2rem;
}

.margin-left-03 {
  margin-left: 0.3rem;
}

.margin-left-04 {
  margin-left: 0.4rem;
}

.margin-left-05 {
  margin-left: 0.5rem;
}

.margin-left-08 {
  margin-left: 0.8rem;
}

.margin-left-1 {
  margin-left: 1rem;
}

.margin-left-1-5 {
  margin-left: 1.5rem;
}

.margin-left-2 {
  margin-left: 2rem;
}

.margin-left-3 {
  margin-left: 3rem;
}

.margin-left-4 {
  margin-left: 4rem;
}

.margin-left-5 {
  margin-left: 5rem;
}

.margin-left-6 {
  margin-left: 6rem;
}

.margin-left-7 {
  margin-left: 7rem;
}

.margin-left-8 {
  margin-left: 8rem;
}

.margin-left-9 {
  margin-left: 9rem;
}

.margin-left-10 {
  margin-left: 10rem;
}

@media (min-width: 576px) {
  .margin-left-xs-1 {
    margin-left: 1rem;
  }
  .margin-left-xs-2 {
    margin-left: 2rem;
  }
  .margin-left-xs-3 {
    margin-left: 3rem;
  }
  .margin-left-xs-4 {
    margin-left: 4rem;
  }
  .margin-left-xs-5 {
    margin-left: 5rem;
  }
  .margin-left-xs-6 {
    margin-left: 6rem;
  }
}

@media (min-width: 771px) {
  .margin-left-sm-1 {
    margin-left: 1rem;
  }
  .margin-left-sm-2 {
    margin-left: 2rem;
  }
  .margin-left-sm-3 {
    margin-left: 3rem;
  }
  .margin-left-sm-4 {
    margin-left: 4rem;
  }
  .margin-left-sm-5 {
    margin-left: 5rem;
  }
  .margin-left-sm-6 {
    margin-left: 6rem;
  }
}

@media (min-width: 992px) {
  .margin-left-md-1 {
    margin-left: 1rem;
  }
  .margin-left-md-2 {
    margin-left: 2rem;
  }
  .margin-left-md-3 {
    margin-left: 3rem;
  }
  .margin-left-md-4 {
    margin-left: 4rem;
  }
  .margin-left-md-5 {
    margin-left: 5rem;
  }
  .margin-left-md-6 {
    margin-left: 6rem;
  }
}

@media (min-width: 1200px) {
  .margin-left-lg-1 {
    margin-left: 1rem;
  }
  .margin-left-lg-2 {
    margin-left: 2rem;
  }
  .margin-left-lg-3 {
    margin-left: 3rem;
  }
  .margin-left-lg-4 {
    margin-left: 4rem;
  }
  .margin-left-lg-5 {
    margin-left: 5rem;
  }
  .margin-left-lg-6 {
    margin-left: 6rem;
  }
}

@media (min-width: 1440px) {
  .margin-left-xl-1 {
    margin-left: 1rem;
  }
  .margin-left-xl-2 {
    margin-left: 2rem;
  }
  .margin-left-xl-3 {
    margin-left: 3rem;
  }
  .margin-left-xl-4 {
    margin-left: 4rem;
  }
  .margin-left-xl-5 {
    margin-left: 5rem;
  }
  .margin-left-xl-6 {
    margin-left: 6rem;
  }
}

.margin-right-01 {
  margin-right: 0.1rem;
}

.margin-right-02 {
  margin-right: 0.2rem;
}

.margin-right-03 {
  margin-right: 0.3rem;
}

.margin-right-04 {
  margin-right: 0.4rem;
}

.margin-right-05 {
  margin-right: 0.5rem;
}

.margin-right-08 {
  margin-right: 0.8rem;
}

.margin-right-1 {
  margin-right: 1rem;
}

.margin-right-1-5 {
  margin-right: 1.5rem;
}

.margin-right-2 {
  margin-right: 2rem;
}

.margin-right-3 {
  margin-right: 3rem;
}

.margin-right-4 {
  margin-right: 4rem;
}

.margin-right-5 {
  margin-right: 5rem;
}

.margin-right-6 {
  margin-right: 6rem;
}

.margin-right-7 {
  margin-right: 7rem;
}

.margin-right-8 {
  margin-right: 8rem;
}

.margin-right-9 {
  margin-right: 9rem;
}

.margin-right-10 {
  margin-right: 10rem;
}

@media (min-width: 576px) {
  .margin-right-xs-1 {
    margin-right: 1rem;
  }
  .margin-right-xs-2 {
    margin-right: 2rem;
  }
  .margin-right-xs-3 {
    margin-right: 3rem;
  }
  .margin-right-xs-4 {
    margin-right: 4rem;
  }
  .margin-right-xs-5 {
    margin-right: 5rem;
  }
  .margin-right-xs-6 {
    margin-right: 6rem;
  }
}

@media (min-width: 771px) {
  .margin-right-sm-1 {
    margin-right: 1rem;
  }
  .margin-right-sm-2 {
    margin-right: 2rem;
  }
  .margin-right-sm-3 {
    margin-right: 3rem;
  }
  .margin-right-sm-4 {
    margin-right: 4rem;
  }
  .margin-right-sm-5 {
    margin-right: 5rem;
  }
  .margin-right-sm-6 {
    margin-right: 6rem;
  }
}

@media (min-width: 992px) {
  .margin-right-md-1 {
    margin-right: 1rem;
  }
  .margin-right-md-2 {
    margin-right: 2rem;
  }
  .margin-right-md-3 {
    margin-right: 3rem;
  }
  .margin-right-md-4 {
    margin-right: 4rem;
  }
  .margin-right-md-5 {
    margin-right: 5rem;
  }
  .margin-right-md-6 {
    margin-right: 6rem;
  }
}

@media (min-width: 1200px) {
  .margin-right-lg-1 {
    margin-right: 1rem;
  }
  .margin-right-lg-2 {
    margin-right: 2rem;
  }
  .margin-right-lg-3 {
    margin-right: 3rem;
  }
  .margin-right-lg-4 {
    margin-right: 4rem;
  }
  .margin-right-lg-5 {
    margin-right: 5rem;
  }
  .margin-right-lg-6 {
    margin-right: 6rem;
  }
}

@media (min-width: 1440px) {
  .margin-right-xl-1 {
    margin-right: 1rem;
  }
  .margin-right-xl-2 {
    margin-right: 2rem;
  }
  .margin-right-xl-3 {
    margin-right: 3rem;
  }
  .margin-right-xl-4 {
    margin-right: 4rem;
  }
  .margin-right-xl-5 {
    margin-right: 5rem;
  }
  .margin-right-xl-6 {
    margin-right: 6rem;
  }
}

.margin-center {
  margin: 0 auto;
}

.margin-left-auto {
  margin-left: auto;
}

.margin-right-auto {
  margin-right: auto;
}

@media (min-width: 576px) {
  .margin-xs-center {
    margin: 0 auto;
  }
  .margin-xs-left-auto {
    margin-left: auto;
  }
  .margin-xs-right-auto {
    margin-right: auto;
  }
}

@media (min-width: 771px) {
  .margin-sm-center {
    margin: 0 auto;
  }
  .margin-sm-left-auto {
    margin-left: auto;
  }
  .margin-sm-right-auto {
    margin-right: auto;
  }
}

@media (min-width: 992px) {
  .margin-md-center {
    margin: 0 auto;
  }
  .margin-md-left-auto {
    margin-left: auto;
  }
  .margin-md-right-auto {
    margin-right: auto;
  }
}

@media (min-width: 1200px) {
  .margin-lg-center {
    margin: 0 auto;
  }
  .margin-lg-left-auto {
    margin-left: auto;
  }
  .margin-lg-right-auto {
    margin-right: auto;
  }
}

.margin-top-0 {
  margin-top: 0 !important;
}

@media (min-width: 576px) {
  .margin-top-xs-0 {
    margin-top: 0 !important;
  }
}

@media (min-width: 771px) {
  .margin-top-sm-0 {
    margin-top: 0 !important;
  }
}

@media (min-width: 992px) {
  .margin-top-md-0 {
    margin-top: 0 !important;
  }
}

@media (min-width: 1200px) {
  .margin-top-lg-0 {
    margin-top: 0 !important;
  }
}

@media (max-width: 1199px) {
  .margin-bp-top-lg-0 {
    margin-top: 0 !important;
  }
}

@media (max-width: 991px) {
  .margin-bp-top-md-0 {
    margin-top: 0 !important;
  }
}

@media (max-width: 770px) {
  .margin-bp-top-sm-0 {
    margin-top: 0 !important;
  }
}

@media (max-width: 575px) {
  .margin-bp-top-xs-0 {
    margin-top: 0 !important;
  }
}

.margin-right-0 {
  margin-right: 0 !important;
}

@media (min-width: 576px) {
  .margin-right-xs-0 {
    margin-right: 0 !important;
  }
}

@media (min-width: 771px) {
  .margin-right-sm-0 {
    margin-right: 0 !important;
  }
}

@media (min-width: 992px) {
  .margin-right-md-0 {
    margin-right: 0 !important;
  }
}

@media (min-width: 1200px) {
  .margin-right-lg-0 {
    margin-right: 0 !important;
  }
}

@media (max-width: 1199px) {
  .margin-bp-right-lg-0 {
    margin-right: 0 !important;
  }
}

@media (max-width: 991px) {
  .margin-bp-right-md-0 {
    margin-right: 0 !important;
  }
}

@media (max-width: 770px) {
  .margin-bp-right-sm-0 {
    margin-right: 0 !important;
  }
}

@media (max-width: 575px) {
  .margin-bp-right-xs-0 {
    margin-right: 0 !important;
  }
}

.margin-bottom-0 {
  margin-bottom: 0 !important;
}

@media (min-width: 576px) {
  .margin-bottom-xs-0 {
    margin-bottom: 0 !important;
  }
}

@media (min-width: 771px) {
  .margin-bottom-sm-0 {
    margin-bottom: 0 !important;
  }
}

@media (min-width: 992px) {
  .margin-bottom-md-0 {
    margin-bottom: 0 !important;
  }
}

@media (min-width: 1200px) {
  .margin-bottom-lg-0 {
    margin-bottom: 0 !important;
  }
}

@media (max-width: 1199px) {
  .margin-bp-bottom-lg-0 {
    margin-bottom: 0 !important;
  }
}

@media (max-width: 991px) {
  .margin-bp-bottom-md-0 {
    margin-bottom: 0 !important;
  }
}

@media (max-width: 770px) {
  .margin-bp-bottom-sm-0 {
    margin-bottom: 0 !important;
  }
}

@media (max-width: 575px) {
  .margin-bp-bottom-xs-0 {
    margin-bottom: 0 !important;
  }
}

.margin-left-0 {
  margin-left: 0 !important;
}

@media (min-width: 576px) {
  .margin-left-xs-0 {
    margin-left: 0 !important;
  }
}

@media (min-width: 771px) {
  .margin-left-sm-0 {
    margin-left: 0 !important;
  }
}

@media (min-width: 992px) {
  .margin-left-md-0 {
    margin-left: 0 !important;
  }
}

@media (min-width: 1200px) {
  .margin-left-lg-0 {
    margin-left: 0 !important;
  }
}

@media (max-width: 1199px) {
  .margin-bp-left-lg-0 {
    margin-left: 0 !important;
  }
}

@media (max-width: 991px) {
  .margin-bp-left-md-0 {
    margin-left: 0 !important;
  }
}

@media (max-width: 770px) {
  .margin-bp-left-sm-0 {
    margin-left: 0 !important;
  }
}

@media (max-width: 575px) {
  .margin-bp-left-xs-0 {
    margin-left: 0 !important;
  }
}

/* Padding block */
.padding-0 {
  padding: 0 !important;
}

@media (min-width: 576px) {
  .padding-xs-0 {
    padding: 0 !important;
  }
}

@media (min-width: 771px) {
  .padding-sm-0 {
    padding: 0 !important;
  }
}

@media (min-width: 992px) {
  .padding-md-0 {
    padding: 0 !important;
  }
}

@media (min-width: 1440px) {
  .padding-xl-0 {
    padding: 0 !important;
  }
}

.padding-top-0 {
  padding-top: 0 !important;
}

.padding-top-xs-0 {
  padding-top: 0 !important;
}

@media (min-width: 771px) {
  .padding-top-sm-0 {
    padding-top: 0 !important;
  }
}

@media (min-width: 992px) {
  .padding-top-md-0 {
    padding-top: 0 !important;
  }
}

@media (min-width: 1200px) {
  .padding-top-lg-0 {
    padding-top: 0 !important;
  }
}

@media (max-width: 1199px) {
  .padding-bp-top-lg-0 {
    padding-top: 0 !important;
  }
}

@media (max-width: 991px) {
  .padding-bp-top-md-0 {
    padding-top: 0 !important;
  }
}

@media (max-width: 770px) {
  .padding-bp-top-sm-0 {
    padding-top: 0 !important;
  }
}

@media (max-width: 575px) {
  .padding-bp-top-xs-0 {
    padding-top: 0 !important;
  }
}

.padding-right-0 {
  padding-right: 0 !important;
}

.padding-right-xs-0 {
  padding-right: 0 !important;
}

@media (min-width: 771px) {
  .padding-right-sm-0 {
    padding-right: 0 !important;
  }
}

@media (min-width: 992px) {
  .padding-right-md-0 {
    padding-right: 0 !important;
  }
}

@media (min-width: 1200px) {
  .padding-right-lg-0 {
    padding-right: 0 !important;
  }
}

@media (max-width: 1199px) {
  .padding-bp-right-lg-0 {
    padding-right: 0 !important;
  }
}

@media (max-width: 991px) {
  .padding-bp-right-md-0 {
    padding-right: 0 !important;
  }
}

@media (max-width: 770px) {
  .padding-bp-right-sm-0 {
    padding-right: 0 !important;
  }
}

@media (max-width: 575px) {
  .padding-bp-right-xs-0 {
    padding-right: 0 !important;
  }
}

.padding-bottom-0 {
  padding-bottom: 0 !important;
}

.padding-bottom-xs-0 {
  padding-bottom: 0 !important;
}

@media (min-width: 771px) {
  .padding-bottom-sm-0 {
    padding-bottom: 0 !important;
  }
}

@media (min-width: 992px) {
  .padding-bottom-md-0 {
    padding-bottom: 0 !important;
  }
}

@media (min-width: 1200px) {
  .padding-bottom-lg-0 {
    padding-bottom: 0 !important;
  }
}

@media (max-width: 1199px) {
  .padding-bp-bottom-lg-0 {
    padding-bottom: 0 !important;
  }
}

@media (max-width: 991px) {
  .padding-bp-bottom-md-0 {
    padding-bottom: 0 !important;
  }
}

@media (max-width: 770px) {
  .padding-bp-bottom-sm-0 {
    padding-bottom: 0 !important;
  }
}

@media (max-width: 575px) {
  .padding-bp-bottom-xs-0 {
    padding-bottom: 0 !important;
  }
}

.padding-left-0 {
  padding-left: 0 !important;
}

.padding-left-xs-0 {
  padding-left: 0 !important;
}

@media (min-width: 771px) {
  .padding-left-sm-0 {
    padding-left: 0 !important;
  }
}

@media (min-width: 992px) {
  .padding-left-md-0 {
    padding-left: 0 !important;
  }
}

@media (min-width: 1200px) {
  .padding-left-lg-0 {
    padding-left: 0 !important;
  }
}

@media (max-width: 1199px) {
  .padding-bp-left-lg-0 {
    padding-left: 0 !important;
  }
}

@media (max-width: 991px) {
  .padding-bp-left-md-0 {
    padding-left: 0 !important;
  }
}

@media (max-width: 770px) {
  .padding-bp-left-sm-0 {
    padding-left: 0 !important;
  }
}

@media (max-width: 575px) {
  .padding-bp-left-xs-0 {
    padding-left: 0 !important;
  }
}

/* Position helpers */
.pos-r {
  position: relative;
}

.pos-f {
  position: fixed;
}

.pos-s {
  position: static;
}

.pos-sticky {
  position: sticky;
}

.pos-a {
  position: absolute;
}

.pos-auto {
  position: inherit;
}

/* Row helpers */
.row-40 {
  margin-right: -40px;
  margin-left: -40px;
}

.row-40 .row-item {
  padding-left: 40px;
  padding-right: 40px;
}

.row-30 {
  margin-right: -30px;
  margin-left: -30px;
}

.row-30 .row-item {
  padding-left: 30px;
  padding-right: 30px;
}

.row-20 {
  margin-right: -20px;
  margin-left: -20px;
}

.row-20 .row-item {
  padding-left: 20px;
  padding-right: 20px;
}

.row-10 {
  margin-right: -10px;
  margin-left: -10px;
}

.row-10 .row-item {
  padding-left: 10px;
  padding-right: 10px;
}

/* Size helpers */
.w-full {
  width: 100%;
}

.w-half {
  width: 50%;
}

.w-quarter {
  width: 25%;
}

.h-full-vh {
  height: 100vh;
}

.h-full {
  height: 100%;
}

.h-half {
  height: 50%;
}

.h-quarter {
  height: 25%;
}

/* Text helpers */
.txt-r {
  text-align: right;
}

.txt-c {
  text-align: center;
}

.txt-l {
  text-align: left;
}

.txt-upper {
  text-transform: uppercase;
}

.txt-lower {
  text-transform: lowercase;
}

.txt-italic {
  font-style: italic;
}

.txt-light {
  font-weight: 300;
}

.txt-regular {
  font-weight: 400;
}

.txt-bold {
  font-weight: 700;
}

.txt-line {
  text-decoration: line-through;
}

.txt-underline {
  text-decoration: underline;
}

.p-size-20 p {
  font-size: 20px;
  font-size: 1.25rem;
}

.p-size-15 p {
  font-size: 15px;
  font-size: 0.9375rem;
}

.p-size-12 p {
  font-size: 12px;
  font-size: 0.75rem;
}

.p-line-2 p {
  line-height: 2;
}

.p-line-1-8 p {
  line-height: 1.8;
}

.p-line-1-6 p {
  line-height: 1.6;
}

.p-line-1-4 p {
  line-height: 1.4;
}

.p-line-1-2 p {
  line-height: 1.2;
}

.p-line-1 p {
  line-height: 1;
}

.h-light h1, .h-light .alfa,
.h-light h2, .h-light .beta,
.h-light h3, .h-light .gamma,
.h-light h4, .h-light .delta,
.h-light h5, .h-light .epsilon,
.h-light h6, .h-light .zeta {
  font-family: "jaf-domus", Arial, sans-serif;
  font-weight: 300;
}

.h-regular h1, .h-regular .alfa,
.h-regular h2, .h-regular .beta,
.h-regular h3, .h-regular .gamma,
.h-regular h4, .h-regular .delta,
.h-regular h5, .h-regular .epsilon,
.h-regular h6, .h-regular .zeta {
  font-family: "jaf-domus", Arial, sans-serif;
  font-weight: 400;
}

.h-semibold h1, .h-semibold .alfa,
.h-semibold h2, .h-semibold .beta,
.h-semibold h3, .h-semibold .gamma,
.h-semibold h4, .h-semibold .delta,
.h-semibold h5, .h-semibold .epsilon,
.h-semibold h6, .h-semibold .zeta {
  font-family: "jaf-domus", Arial, sans-serif;
  font-weight: 600;
}

.h-bold h1, .h-bold .alfa,
.h-bold h2, .h-bold .beta,
.h-bold h3, .h-bold .gamma,
.h-bold h4, .h-bold .delta,
.h-bold h5, .h-bold .epsilon,
.h-bold h6, .h-bold .zeta {
  font-family: "jaf-domus", Arial, sans-serif;
  font-weight: 700;
}

.h-black h1, .h-black .alfa,
.h-black h2, .h-black .beta,
.h-black h3, .h-black .gamma,
.h-black h4, .h-black .delta,
.h-black h5, .h-black .epsilon,
.h-black h6, .h-black .zeta {
  font-family: "jaf-domus", Arial, sans-serif;
  font-weight: 900;
}

.h-upper h1, .h-upper .alfa,
.h-upper h2, .h-upper .beta,
.h-upper h3, .h-upper .gamma,
.h-upper h4, .h-upper .delta,
.h-upper h5, .h-upper .epsilon,
.h-upper h6, .h-upper .zeta {
  text-transform: uppercase;
}

.h-line-1 h1, .h-line-1 .alfa,
.h-line-1 h2, .h-line-1 .beta,
.h-line-1 h3, .h-line-1 .gamma,
.h-line-1 h4, .h-line-1 .delta,
.h-line-1 h5, .h-line-1 .epsilon,
.h-line-1 h6, .h-line-1 .zeta {
  line-height: 1;
}

.h-line-1-2 h1, .h-line-1-2 .alfa,
.h-line-1-2 h2, .h-line-1-2 .beta,
.h-line-1-2 h3, .h-line-1-2 .gamma,
.h-line-1-2 h4, .h-line-1-2 .delta,
.h-line-1-2 h5, .h-line-1-2 .epsilon,
.h-line-1-2 h6, .h-line-1-2 .zeta {
  line-height: 1.2;
}

.h-line-1-4 h1, .h-line-1-4 .alfa,
.h-line-1-4 h2, .h-line-1-4 .beta,
.h-line-1-4 h3, .h-line-1-4 .gamma,
.h-line-1-4 h4, .h-line-1-4 .delta,
.h-line-1-4 h5, .h-line-1-4 .epsilon,
.h-line-1-4 h6, .h-line-1-4 .zeta {
  line-height: 1.4;
}

.h-line-1-6 h1, .h-line-1-6 .alfa,
.h-line-1-6 h2, .h-line-1-6 .beta,
.h-line-1-6 h3, .h-line-1-6 .gamma,
.h-line-1-6 h4, .h-line-1-6 .delta,
.h-line-1-6 h5, .h-line-1-6 .epsilon,
.h-line-1-6 h6, .h-line-1-6 .zeta {
  line-height: 1.6;
}

.h-line-1-8 h1, .h-line-1-8 .alfa,
.h-line-1-8 h2, .h-line-1-8 .beta,
.h-line-1-8 h3, .h-line-1-8 .gamma,
.h-line-1-8 h4, .h-line-1-8 .delta,
.h-line-1-8 h5, .h-line-1-8 .epsilon,
.h-line-1-8 h6, .h-line-1-8 .zeta {
  line-height: 1.8;
}

.h-line-2 h1, .h-line-2 .alfa,
.h-line-2 h2, .h-line-2 .beta,
.h-line-2 h3, .h-line-2 .gamma,
.h-line-2 h4, .h-line-2 .delta,
.h-line-2 h5, .h-line-2 .epsilon,
.h-line-2 h6, .h-line-2 .zeta {
  line-height: 2;
}

@media (min-width: 576px) {
  .h-line-xs-1-2 h1, .h-line-xs-1-2 .alfa,
  .h-line-xs-1-2 h2, .h-line-xs-1-2 .beta,
  .h-line-xs-1-2 h3, .h-line-xs-1-2 .gamma,
  .h-line-xs-1-2 h4, .h-line-xs-1-2 .delta,
  .h-line-xs-1-2 h5, .h-line-xs-1-2 .epsilon,
  .h-line-xs-1-2 h6, .h-line-xs-1-2 .zeta {
    line-height: 1.2;
  }
}

@media (min-width: 771px) {
  .h-line-sm-1-2 h1, .h-line-sm-1-2 .alfa,
  .h-line-sm-1-2 h2, .h-line-sm-1-2 .beta,
  .h-line-sm-1-2 h3, .h-line-sm-1-2 .gamma,
  .h-line-sm-1-2 h4, .h-line-sm-1-2 .delta,
  .h-line-sm-1-2 h5, .h-line-sm-1-2 .epsilon,
  .h-line-sm-1-2 h6, .h-line-sm-1-2 .zeta {
    line-height: 1.2;
  }
}

@media (min-width: 370px) {
  .txt-xss-c {
    text-align: center;
  }
  .txt-xss-l {
    text-align: left;
  }
  .txt-xss-r {
    text-align: right;
  }
}

@media (min-width: 576px) {
  .txt-xs-c {
    text-align: center;
  }
  .txt-xs-l {
    text-align: left;
  }
  .txt-xs-r {
    text-align: right;
  }
}

@media (min-width: 771px) {
  .txt-sm-l {
    text-align: left;
  }
  .txt-sm-r {
    text-align: right;
  }
  .txt-sm-c {
    text-align: center;
  }
}

@media (min-width: 992px) {
  .txt-md-l {
    text-align: left;
  }
  .txt-md-r {
    text-align: right;
  }
  .txt-md-c {
    text-align: center;
  }
}

@media (min-width: 1200px) {
  .txt-lg-r {
    text-align: right;
  }
  .txt-lg-l {
    text-align: left;
  }
  .txt-lg-c {
    text-align: center;
  }
}

.h-margin-0 .alfa, .h-margin-0 .beta, .h-margin-0 .gamma, .h-margin-0 .delta, .h-margin-0 .epsilon, .h-margin-0 .zeta,
.h-margin-0 h1, .h-margin-0 h2, .h-margin-0 h3, .h-margin-0 h4, .h-margin-0 h5, .h-margin-0 h6 {
  margin-bottom: 0;
}

.h-margin-01 .alfa, .h-margin-01 .beta, .h-margin-01 .gamma, .h-margin-01 .delta, .h-margin-01 .epsilon, .h-margin-01 .zeta,
.h-margin-01 h1, .h-margin-01 h2, .h-margin-01 h3, .h-margin-01 h4, .h-margin-01 h5, .h-margin-01 h6 {
  margin-bottom: 0.1rem;
}

.h-margin-02 .alfa, .h-margin-02 .beta, .h-margin-02 .gamma, .h-margin-02 .delta, .h-margin-02 .epsilon, .h-margin-02 .zeta,
.h-margin-02 h1, .h-margin-02 h2, .h-margin-02 h3, .h-margin-02 h4, .h-margin-02 h5, .h-margin-02 h6 {
  margin-bottom: 0.2rem;
}

.h-margin-03 .alfa, .h-margin-03 .beta, .h-margin-03 .gamma, .h-margin-03 .delta, .h-margin-03 .epsilon, .h-margin-03 .zeta,
.h-margin-03 h1, .h-margin-03 h2, .h-margin-03 h3, .h-margin-03 h4, .h-margin-03 h5, .h-margin-03 h6 {
  margin-bottom: 0.3rem;
}

.h-margin-04 .alfa, .h-margin-04 .beta, .h-margin-04 .gamma, .h-margin-04 .delta, .h-margin-04 .epsilon, .h-margin-04 .zeta,
.h-margin-04 h1, .h-margin-04 h2, .h-margin-04 h3, .h-margin-04 h4, .h-margin-04 h5, .h-margin-04 h6 {
  margin-bottom: 0.4rem;
}

.h-margin-05 .alfa, .h-margin-05 .beta, .h-margin-05 .gamma, .h-margin-05 .delta, .h-margin-05 .epsilon, .h-margin-05 .zeta,
.h-margin-05 h1, .h-margin-05 h2, .h-margin-05 h3, .h-margin-05 h4, .h-margin-05 h5, .h-margin-05 h6 {
  margin-bottom: 0.5rem;
}

.h-margin-06 .alfa, .h-margin-06 .beta, .h-margin-06 .gamma, .h-margin-06 .delta, .h-margin-06 .epsilon, .h-margin-06 .zeta,
.h-margin-06 h1, .h-margin-06 h2, .h-margin-06 h3, .h-margin-06 h4, .h-margin-06 h5, .h-margin-06 h6 {
  margin-bottom: 0.6rem;
}

.h-margin-08 .alfa, .h-margin-08 .beta, .h-margin-08 .gamma, .h-margin-08 .delta, .h-margin-08 .epsilon, .h-margin-08 .zeta,
.h-margin-08 h1, .h-margin-08 h2, .h-margin-08 h3, .h-margin-08 h4, .h-margin-08 h5, .h-margin-08 h6 {
  margin-bottom: 0.8rem;
}

.h-margin-1 .alfa, .h-margin-1 .beta, .h-margin-1 .gamma, .h-margin-1 .delta, .h-margin-1 .epsilon, .h-margin-1 .zeta,
.h-margin-1 h1, .h-margin-1 h2, .h-margin-1 h3, .h-margin-1 h4, .h-margin-1 h5, .h-margin-1 h6 {
  margin-bottom: 1rem;
}

.h-margin-1-5 .alfa, .h-margin-1-5 .beta, .h-margin-1-5 .gamma, .h-margin-1-5 .delta, .h-margin-1-5 .epsilon, .h-margin-1-5 .zeta,
.h-margin-1-5 h1, .h-margin-1-5 h2, .h-margin-1-5 h3, .h-margin-1-5 h4, .h-margin-1-5 h5, .h-margin-1-5 h6 {
  margin-bottom: 1.5rem;
}

.h-margin-2 .alfa, .h-margin-2 .beta, .h-margin-2 .gamma, .h-margin-2 .delta, .h-margin-2 .epsilon, .h-margin-2 .zeta,
.h-margin-2 h1, .h-margin-2 h2, .h-margin-2 h3, .h-margin-2 h4, .h-margin-2 h5, .h-margin-2 h6 {
  margin-bottom: 2rem;
}

.p-margin-0 p {
  margin-bottom: 0;
}

.p-margin-01 p {
  margin-bottom: 0.1rem;
}

.p-margin-02 p {
  margin-bottom: 0.2rem;
}

.p-margin-03 p {
  margin-bottom: 0.3rem;
}

.p-margin-04 p {
  margin-bottom: 0.4rem;
}

.p-margin-05 p {
  margin-bottom: 0.5rem;
}

.p-margin-08 p {
  margin-bottom: 0.8rem;
}

.p-margin-1 p {
  margin-bottom: 1rem;
}

.p-margin-1-5 p {
  margin-bottom: 1.5rem;
}

.p-margin-2 p {
  margin-bottom: 2rem;
}

.dots {
  overflow: hidden;
}

/* Base settings */
*, *::before, *::after {
  box-sizing: border-box;
}

* {
  transition: color .15s, background .15s, border .15s, opacity .15s;
}

*:after, *:before {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  outline: none;
}

::-moz-selection {
  color: #fff;
  background-color: #2c81d0;
}

::selection {
  color: #fff;
  background-color: #2c81d0;
}

@-ms-viewport {
  width: device-width;
}

article, aside, details, figcaption, figure, footer, header, hgroup, main, menu, nav, section, summary {
  display: block;
}

html {
  position: relative;
  box-sizing: border-box;
  height: 100%;
  font-size: 100%;
}

body {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 310px;
  height: 100%;
  margin: 0;
  color: #000;
  background-color: #ffffff;
  font-family: "jaf-domus", Arial, sans-serif;
  font-weight: 400;
  line-height: 1;
}

a {
  cursor: pointer;
  text-decoration: none;
  color: inherit;
  outline: none;
  font-family: "jaf-domus", Arial, sans-serif;
}

a:focus, a:active, a:visited {
  outline: none;
}

.a-hover:hover, .a-hover:focus {
  text-decoration: underline;
}

.a-link {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.a-link-text {
  color: #2c81d0;
  font-size: 20px;
  font-size: 1.25rem;
}

.a-link-icon {
  display: inline-block;
  margin-left: 5px;
  vertical-align: middle;
  color: #2c81d0;
  font-size: 24px;
  font-size: 1.5rem;
}

.a-link-underline {
  text-decoration: underline;
}

.a-hover-link-underline {
  position: relative;
}

.a-hover-link-underline:after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  display: block;
  width: 0;
  height: 1px;
  transition: width 250ms ease-out;
  background-color: currentColor;
}

.a-hover-link-underline:hover:after {
  width: 100%;
}

.a-hover-link-underline-block .a-hover-link-underline {
  position: relative;
}

.a-hover-link-underline-block .a-hover-link-underline:after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  display: block;
  width: 0;
  height: 1px;
  transition: width 250ms ease-out;
  background-color: currentColor;
}

.a-hover-link-underline-block:hover .a-hover-link-underline:after {
  width: 100%;
}

p {
  display: block;
  margin-top: 0;
  margin-bottom: 1.5rem;
  color: #8f8f8f;
  font-family: "jaf-domus", Arial, sans-serif;
  font-weight: 400;
  line-height: 1.6;
  font-size: 18px;
  font-size: 1.125rem;
}

@media (min-width: 771px) {
  p {
    margin-bottom: 2.5rem;
  }
}

@media (min-width: 992px) {
  p {
    font-size: 20px;
    font-size: 1.25rem;
  }
}

ol, ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

ul.list-style, ol.list-style {
  margin-bottom: 2em;
}

ul.list-style li, ol.list-style li {
  position: relative;
  display: block;
  margin-bottom: 8px;
  padding-left: 25px;
  color: #424242;
  font-family: "jaf-domus", Arial, sans-serif;
  font-weight: 400;
  line-height: 1.4;
  font-size: 16px;
  font-size: 1rem;
}

ul.list-style a, ol.list-style a {
  color: #2c81d0;
}

ul.list-style a:hover, ol.list-style a:hover {
  text-decoration: underline;
}

ul.list-style li:before {
  position: absolute;
  top: 9px;
  left: 3px;
  display: block;
  width: 6px;
  height: 6px;
  content: "";
  color: #424242;
  border-radius: 100%;
  background-color: #424242;
}

ol.list-style {
  list-style-position: inside;
  counter-reset: item;
}

ol.list-style li:before {
  position: absolute;
  top: 0;
  left: 0;
  display: inline-block;
  content: counter(item) ".";
  counter-increment: item;
  color: #424242;
  line-height: 1.4;
  font-size: 16px;
  font-size: 1rem;
}

em, i, .italic {
  font-style: italic;
}

strong, .strong {
  color: #424242;
  font-weight: 700;
}

small, .small {
  font-size: 12px;
  font-size: 0.75rem;
}

h1, .alfa, h2, .beta, h3, .gamma, h4, .delta, h5, .epsilon, h6, .zeta {
  margin: 0;
  margin-bottom: 1.5rem;
  color: #424242;
  font-family: "jaf-domus", Arial, sans-serif;
  font-weight: 300;
  line-height: 1.2;
  letter-spacing: 0.11px;
}

h1 a, .alfa a, h2 a, .beta a, h3 a, .gamma a, h4 a, .delta a, h5 a, .epsilon a, h6 a, .zeta a {
  color: inherit;
}

h1, .alfa {
  font-size: 46px;
  font-size: 2.875rem;
}

@media (min-width: 576px) {
  h1, .alfa {
    font-size: 50px;
    font-size: 3.125rem;
  }
}

@media (min-width: 771px) {
  h1, .alfa {
    font-size: 65px;
    font-size: 4.0625rem;
  }
}

@media (min-width: 992px) {
  h1, .alfa {
    font-size: 74px;
    font-size: 4.625rem;
  }
}

@media (min-width: 1200px) {
  h1, .alfa {
    font-size: 90px;
    font-size: 5.625rem;
  }
}

h2, .beta {
  font-size: 28px;
  font-size: 1.75rem;
}

@media (min-width: 576px) {
  h2, .beta {
    font-size: 32px;
    font-size: 2rem;
  }
}

@media (min-width: 771px) {
  h2, .beta {
    font-size: 36px;
    font-size: 2.25rem;
  }
}

@media (min-width: 992px) {
  h2, .beta {
    font-size: 40px;
    font-size: 2.5rem;
  }
}

@media (min-width: 1200px) {
  h2, .beta {
    font-size: 45px;
    font-size: 2.8125rem;
  }
}

h3, .gamma {
  font-size: 18px;
  font-size: 1.125rem;
}

@media (min-width: 576px) {
  h3, .gamma {
    font-size: 20px;
    font-size: 1.25rem;
  }
}

@media (min-width: 771px) {
  h3, .gamma {
    font-size: 20px;
    font-size: 1.25rem;
  }
}

.h-divider h1, .h-divider .alfa,
.h-divider h2, .h-divider .beta,
.h-divider h3, .h-divider .gamma,
.h-divider h4, .h-divider .delta,
.h-divider h5, .h-divider .epsilon,
.h-divider h6, .h-divider .zeta {
  position: relative;
}

.h-divider h1:after, .h-divider .alfa:after,
.h-divider h2:after, .h-divider .beta:after,
.h-divider h3:after, .h-divider .gamma:after,
.h-divider h4:after, .h-divider .delta:after,
.h-divider h5:after, .h-divider .epsilon:after,
.h-divider h6:after, .h-divider .zeta:after {
  content: '';
  display: block;
  width: 40px;
  height: 2px;
  box-shadow: 0 0 91px rgba(0, 0, 0, 0.05);
  border-radius: 1px;
  background-color: #dce6fa;
  margin-top: 15px;
}

.h-divider-small h1, .h-divider-small .alfa,
.h-divider-small h2, .h-divider-small .beta,
.h-divider-small h3, .h-divider-small .gamma,
.h-divider-small h4, .h-divider-small .delta,
.h-divider-small h5, .h-divider-small .epsilon,
.h-divider-small h6, .h-divider-small .zeta {
  position: relative;
}

.h-divider-small h1:after, .h-divider-small .alfa:after,
.h-divider-small h2:after, .h-divider-small .beta:after,
.h-divider-small h3:after, .h-divider-small .gamma:after,
.h-divider-small h4:after, .h-divider-small .delta:after,
.h-divider-small h5:after, .h-divider-small .epsilon:after,
.h-divider-small h6:after, .h-divider-small .zeta:after {
  margin-top: 20px;
  width: 20px;
}

.h-divider-big h1, .h-divider-big .alfa,
.h-divider-big h2, .h-divider-big .beta,
.h-divider-big h3, .h-divider-big .gamma,
.h-divider-big h4, .h-divider-big .delta,
.h-divider-big h5, .h-divider-big .epsilon,
.h-divider-big h6, .h-divider-big .zeta {
  position: relative;
}

.h-divider-big h1:after, .h-divider-big .alfa:after,
.h-divider-big h2:after, .h-divider-big .beta:after,
.h-divider-big h3:after, .h-divider-big .gamma:after,
.h-divider-big h4:after, .h-divider-big .delta:after,
.h-divider-big h5:after, .h-divider-big .epsilon:after,
.h-divider-big h6:after, .h-divider-big .zeta:after {
  margin-top: 40px;
}

/* Content */
section {
  position: relative;
  padding: 3rem 0 3rem;
}

@media (min-width: 771px) {
  section {
    padding: 3rem 0 3rem;
  }
}

.no-padding {
  padding: 0;
}

@media (min-width: 576px) {
  .no-xs-padding {
    padding: 0;
  }
}

@media (min-width: 771px) {
  .no-sm-padding {
    padding: 0;
  }
}

@media (min-width: 992px) {
  .no-md-padding {
    padding: 0;
  }
}

@media (min-width: 1200px) {
  .no-lg-padding {
    padding: 0;
  }
}

.padding-style-1 {
  padding: 3rem 0 1.5rem;
}

@media (min-width: 771px) {
  .padding-style-1 {
    padding: 1.5rem 0 1.5rem;
  }
}

.padding-style-1-ext {
  padding: 1rem 0 1rem;
}

@media (min-width: 771px) {
  .padding-style-1-ext {
    padding: 1rem 0 1rem;
  }
}

.padding-style-2 {
  padding: 2rem 0.5rem 2rem;
}

@media (min-width: 771px) {
  .padding-style-2 {
    padding: 2rem 0 2rem;
  }
}

.padding-style-3 {
  padding: 2rem 0.5rem 2rem;
}

@media (min-width: 771px) {
  .padding-style-3 {
    padding: 3rem 0 3rem;
  }
}

.padding-style-4 {
  padding: 3rem 0.5rem 3rem;
}

@media (min-width: 771px) {
  .padding-style-4 {
    padding: 4rem 0 4rem;
  }
}

.padding-style-4-6 {
  padding: 3rem 0.5rem 4rem;
}

@media (min-width: 771px) {
  .padding-style-4-6 {
    padding: 4rem 0 6rem;
  }
}

.padding-style-5 {
  padding: 3rem 0.5rem 3rem;
}

@media (min-width: 771px) {
  .padding-style-5 {
    padding: 5rem 0 5rem;
  }
}

.padding-content-1 {
  padding: 1rem 1rem;
}

.padding-content-2 {
  padding: 2rem 2rem;
}

.padding-content-3 {
  padding: 3rem 3rem;
}

.padding-content-4 {
  padding: 4rem 4rem;
}

.padding-content-5 {
  padding: 5rem 5rem;
}

.content-width-200 {
  max-width: 200px;
}

.content-width-300 {
  max-width: 300px;
}

.content-width-400 {
  max-width: 400px;
}

.page-index main {
  padding-top: 0;
}

main {
  position: relative;
  display: block;
  padding-top: 60px;
  -webkit-box-flex: 1;
  -webkit-flex: 1 0 auto;
  -ms-flex: 1 0 auto;
  flex: 1 0 auto;
}

@media (min-width: 992px) {
  main {
    padding-top: 70px;
  }
}

.container-full {
  padding: 0 15px;
}

.container-small,
.container-normal,
.container-large {
  margin: 0 auto;
  padding: 0 15px;
}

@media (min-width: 771px) {
  .container-small {
    width: 730px;
  }
}

@media (min-width: 992px) {
  .container-small {
    width: 800px;
  }
}

@media (min-width: 1200px) {
  .container-small {
    width: 800px;
  }
}

@media (min-width: 1440px) {
  .container-small {
    width: 800px;
  }
}

@media (max-width: 770px) {
  .container-small {
    width: 100%;
  }
}

@media (min-width: 771px) {
  .container-normal {
    width: 730px;
  }
}

@media (min-width: 992px) {
  .container-normal {
    width: 950px;
  }
}

@media (min-width: 1200px) {
  .container-normal {
    width: 1130px;
  }
}

@media (min-width: 1440px) {
  .container-normal {
    width: 1200px;
  }
}

@media (max-width: 770px) {
  .container-normal {
    width: 100%;
  }
}

@media (min-width: 771px) {
  .container-large {
    width: 730px;
  }
}

@media (min-width: 992px) {
  .container-large {
    width: 950px;
  }
}

@media (min-width: 1200px) {
  .container-large {
    width: 1140px;
  }
}

@media (min-width: 1440px) {
  .container-large {
    width: 1360px;
  }
}

@media (max-width: 770px) {
  .container-large {
    width: 100%;
  }
}

/* Footer */
.footer {
  position: relative;
  z-index: 2;
  padding: 3rem 0 1rem;
  -webkit-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
}

@media (min-width: 576px) {
  .footer {
    padding: 3rem 0 3rem;
  }
}

@media (min-width: 576px) {
  .contact-list {
    width: 50%;
  }
}

@media (max-width: 575px) {
  .contact-list {
    margin: 0 auto;
    max-width: 250px;
    left: 10px;
    position: relative;
  }
}

@media (max-width: 991px) {
  .contact-list-small {
    margin: 2rem auto 0;
    max-width: 230px;
    width: 100%;
    left: 15px;
    position: relative;
  }
}

.contact-list-small .contact-list-link-icon {
  position: relative;
  top: 2px;
}

.contact-list-small .contact-list-link-icon svg {
  font-size: 18px;
  font-size: 1.125rem;
}

.contact-list-small .contact-list-link-text {
  padding-left: 1rem;
  font-size: 16px;
  font-size: 1rem;
  min-width: 180px;
}

.contact-list-item {
  display: block;
  margin-bottom: 1.2rem;
}

@media (min-width: 576px) {
  .contact-list-item:last-child {
    margin-bottom: 0;
  }
}

.contact-list-item-ext .contact-list-link-text {
  position: relative;
  line-height: 1.8;
  top: -3px;
}

.contact-list-link {
  display: inline-block;
  vertical-align: top;
}

@media (min-width: 771px) {
  .contact-list-link {
    display: inline-flex;
  }
}

.contact-list-link:hover .contact-list-link-text {
  color: #2c81d0;
}

.contact-list-link-icon {
  position: relative;
  top: 2px;
  display: inline-block;
  vertical-align: top;
}

@media (min-width: 771px) {
  .contact-list-link-icon {
    width: 30px;
    max-width: 30px;
    flex: 0 0 30px;
  }
}

.contact-list-link-icon svg {
  fill: #2c81d0;
  font-size: 20px;
  font-size: 1.25rem;
}

@media (min-width: 771px) {
  .contact-list-link-icon svg {
    font-size: 24px;
    font-size: 1.5rem;
  }
}

@media (min-width: 992px) {
  .contact-list-link-icon svg {
    font-size: 24px;
    font-size: 1.5rem;
  }
}

@media (min-width: 1200px) {
  .contact-list-link-icon svg {
    font-size: 28px;
    font-size: 1.75rem;
  }
}

.contact-list-link-text {
  padding-left: 1rem;
  color: #8f8f8f;
  font-weight: 300;
  line-height: 1.4;
  text-transform: uppercase;
  letter-spacing: 0.12px;
  font-size: 18px;
  font-size: 1.125rem;
  display: inline-block;
  vertical-align: top;
}

@media (min-width: 771px) {
  .contact-list-link-text {
    flex: 0 0 calc(100% - 30px);
    padding-left: 1rem;
    font-size: 20px;
    font-size: 1.25rem;
  }
}

@media (min-width: 992px) {
  .contact-list-link-text {
    font-size: 20px;
    font-size: 1.25rem;
  }
}

@media (min-width: 1200px) {
  .contact-list-link-text {
    font-size: 24px;
    font-size: 1.5rem;
  }
}

/* Header */
.navigation .navigation-trigger {
  display: none;
}

@media (max-width: 991px) {
  .navigation .container-normal, .navigation .container-large {
    width: 100%;
  }
  .navigation .nav-collapse {
    position: fixed;
    z-index: 98;
    top: 60px;
    left: 0;
    overflow: auto;
    width: 100%;
    height: 100%;
    padding: 25px 25px 70px;
    transform: translateX(-100%);
    background-color: #ffffff;
    transition: none 0.4s ease-out;
  }
  .navigation .navigation-trigger {
    position: fixed;
    z-index: 200;
    top: 0;
    right: 0;
    display: block;
    width: 70px;
    height: 60px;
  }
  .navigation .navigation-trigger-icon {
    position: absolute;
    top: 50%;
    left: 50%;
    display: inline-block;
    width: 30px;
    height: 3px;
    transition-duration: 250ms;
    transition-property: background-color, transform;
    transform: translate(-50%, -50%);
    background-color: #2c81d0;
    font-weight: 300;
  }
  .navigation .navigation-trigger-icon:before, .navigation .navigation-trigger-icon:after {
    position: absolute;
    display: block;
    width: 30px;
    height: 3px;
    content: '';
    transition-duration: 250ms;
    transition-property: margin, transform;
    background: #2c81d0;
  }
  .navigation .navigation-trigger-icon:before {
    margin-top: -10px;
  }
  .navigation .navigation-trigger-icon:after {
    margin-top: 10px;
  }
  .navigation.is-collapse .nav-collapse {
    transform: translateX(0);
    transition: transform 250ms ease;
  }
  .navigation.is-collapse .navigation-trigger-icon {
    transition: none;
    background: rgba(0, 0, 0, 0);
  }
  .navigation.is-collapse .navigation-trigger-icon:before {
    margin-top: 0;
    transform: rotate(45deg);
  }
  .navigation.is-collapse .navigation-trigger-icon:after {
    margin-top: 0;
    transform: rotate(-45deg);
  }
}

.navigation {
  position: fixed;
  z-index: 999;
  top: 0;
  left: 0;
  width: 100%;
  padding: 0 10px;
  transition: box-shadow 400ms ease-out;
}

@media (min-width: 992px) {
  .navigation {
    padding: 0 30px;
    background-color: #ffffff;
  }
}

@media (max-width: 991px) {
  .navigation {
    background-color: #ffffff;
    box-shadow: 0 5px 10px rgba(1, 1, 1, 0.1);
  }
}

@media (min-width: 992px) {
  .page-index .header-logo {
    height: 150px;
  }
}

@media (min-width: 992px) {
  .page-index .navigation:not(.navigation-scroll) {
    background-color: transparent;
    box-shadow: none;
  }
}

@media (min-width: 992px) {
  .page-index .navigation:not(.navigation-scroll) .menu-list-link {
    color: #ffffff;
  }
  .page-index .navigation:not(.navigation-scroll) .menu-list-link:after {
    background-color: #ffffff;
  }
}

.navigation-scroll {
  background-color: #ffffff;
  box-shadow: 0 5px 10px rgba(1, 1, 1, 0.1);
}

@media (min-width: 992px) {
  .navigation-scroll .header-logo {
    height: 70px;
  }
}

@media (min-width: 992px) {
  .header-flex {
    display: flex;
    justify-content: space-between;
  }
}

.header-logo {
  float: left;
  padding: 10px 0 10px;
  text-align: center;
  transition: height 400ms ease-out;
  display: flex;
  align-items: center;
  height: 70px;
}

@media (max-width: 991px) {
  .header-logo {
    height: auto;
    position: relative;
    z-index: 99;
    padding: 12px 0 12px;
  }
}

.header-logo-link {
  display: inline-block;
  vertical-align: middle;
}

.header-image {
  margin: 0 auto;
  height: 36px;
}

@media (max-width: 991px) {
  .header-image {
    width: 220px;
  }
}

@media (min-width: 992px) {
  .nav-collapse {
    display: flex;
  }
}

.menu-list {
  text-align: center;
}

@media (min-width: 992px) {
  .menu-list {
    display: flex;
    align-items: center;
  }
}

@media (min-width: 993px) and (max-width: 1200px) {
  .menu-list {
    margin-right: -20px;
  }
}

@media (min-width: 992px) {
  .menu-list-item {
    display: flex;
    margin-left: 3rem;
  }
  .menu-list-item:first-child {
    margin-left: 0;
  }
}

@media (min-width: 993px) and (max-width: 1200px) {
  .menu-list-item {
    margin-left: 2.5rem;
  }
}

@media (max-width: 991px) {
  .menu-list-item {
    display: block;
    margin-bottom: 0.8rem;
  }
}

.menu-list-item.is-active .menu-list-link:after {
  width: 100%;
}

.menu-list-link {
  position: relative;
  display: inline-block;
  padding: 0.7rem 0;
  text-decoration: none;
  color: #2c81d0;
  line-height: 1.2;
  text-transform: uppercase;
  letter-spacing: 0.45px;
  font-weight: 700;
  font-size: 18px;
  font-size: 1.125rem;
}

@media (min-width: 992px) {
  .menu-list-link {
    display: flex;
    align-items: center;
    color: #2c81d0;
    font-size: 15px;
    font-size: 0.9375rem;
    padding: 0.5rem 0;
  }
}

.menu-list-link:hover:after {
  width: 100%;
}

.menu-list-link:after {
  position: absolute;
  bottom: 0;
  left: 0;
  display: block;
  width: 0;
  height: 2px;
  content: '';
  transition: width 250ms ease-out;
  background-color: #2c81d0;
}

@media (min-width: 992px) {
  .menu-list-link:after {
    background-color: #2c81d0;
  }
}

/* Map block */
.map-block {
  width: 100%;
  height: 450px;
  background-color: #f5f7ff;
  position: relative;
}

/* Blog block */
.blog-item {
  margin-bottom: 2rem;
}

.blog-content {
  display: block;
  position: relative;
  box-shadow: 0 0 51px rgba(10, 34, 51, 0.07);
  border-radius: 5px;
  background-color: #ffffff;
}

@media (min-width: 576px) {
  .blog-content {
    display: flex;
  }
}

.blog-content:hover .blog-title {
  color: #2c81d0;
}

.blog-content:hover img {
  transform: scale(1.05, 1.05);
}

.blog-image-block {
  display: block;
  position: relative;
  overflow: hidden;
  background-color: #2cd5ff;
  border-radius: 5px 5px 0 0;
}

@media (min-width: 576px) {
  .blog-image-block {
    border-radius: 5px 0 0 5px;
    display: flex;
    width: 230px;
    max-width: 230px;
    flex: 0 0 230px;
  }
}

@media (min-width: 771px) {
  .blog-image-block {
    width: 270px;
    max-width: 270px;
    flex: 0 0 270px;
  }
}

@media (min-width: 993px) and (max-width: 1200px) {
  .blog-image-block {
    width: 200px;
    max-width: 200px;
    flex: 0 0 200px;
  }
}

.blog-image-block img {
  transition: transform 400ms ease-out;
}

.blog-text-block {
  padding: 1.5rem 1.2rem 1.5rem 2rem;
}

@media (min-width: 576px) {
  .blog-text-block {
    align-self: center;
    width: calc(100% - 230px);
    max-width: calc(100% - 230px);
    flex: 0 0 calc(100% - 230px);
  }
}

@media (min-width: 771px) {
  .blog-text-block {
    width: calc(100% - 270px);
    max-width: calc(100% - 270px);
    flex: 0 0 calc(100% - 270px);
  }
}

@media (min-width: 993px) and (max-width: 1200px) {
  .blog-text-block {
    padding: 1.5rem 1rem 1.5rem 1.5rem;
    width: calc(100% - 200px);
    max-width: calc(100% - 200px);
    flex: 0 0 calc(100% - 200px);
  }
}

.blog-date {
  position: absolute;
  top: 20px;
  left: -12px;
  border-radius: 20px;
  background-color: #2c81d0;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 35px;
  min-width: 105px;
  color: #ffffff;
  font-size: 15px;
  font-size: 0.9375rem;
  font-weight: 300;
  line-height: 1.2;
  letter-spacing: 0.75px;
  z-index: 2;
}

/* Breadcrumbs block */
.breadcrumb-list-item {
  display: inline-block;
  vertical-align: middle;
}

@media (max-width: 770px) {
  .breadcrumb-list-item:last-child .breadcrumb-list-link {
    margin-right: 0;
  }
}

.breadcrumb-list-item:last-child .breadcrumb-list-link:after {
  content: none;
}

.breadcrumb-list-link {
  display: inline-block;
  vertical-align: middle;
  line-height: 1.2;
  color: #8f8f8f;
  font-size: 14px;
  font-size: 0.875rem;
  padding: 0.5rem 1.5rem 0.5rem 0;
  position: relative;
}

@media (min-width: 771px) {
  .breadcrumb-list-link {
    padding: 0.5rem 0 0.5rem 1.5rem;
  }
}

.breadcrumb-list-link:hover {
  color: #2c81d0;
}

.breadcrumb-list-link:after {
  content: '';
  position: absolute;
  right: 5px;
  top: 11px;
  display: inline-block;
  width: 12px;
  height: 12px;
  vertical-align: middle;
  background-repeat: no-repeat;
  background-position: center;
  -webkit-background-size: cover;
  background-size: cover;
  background-image: url(../images/icons/chevron-right.svg);
}

@media (min-width: 771px) {
  .breadcrumb-list-link:after {
    right: -20px;
  }
}

.hero-section {
  position: relative;
  padding: 8rem 0.5rem 10rem;
}

@media (min-width: 992px) {
  .hero-section {
    padding: 10rem 0 5rem;
  }
}

@media (min-width: 772px) and (max-width: 992px) {
  .hero-section .btn {
    font-size: 13px;
    font-size: 0.8125rem;
  }
}

@media (max-width: 770px) {
  .hero-content {
    max-width: 300px;
  }
}

@media (max-width: 575px) {
  .hero-content {
    max-width: 340px;
  }
}

.bg-image-hero-bg {
  position: absolute;
  left: auto;
  right: -170px;
  top: auto;
  bottom: -210px;
  width: 364px;
  height: 260px;
  transform: rotateZ(90deg);
}

@media (min-width: 370px) {
  .bg-image-hero-bg {
    top: auto;
    right: -190px;
    bottom: -250px;
    width: 433px;
    height: 310px;
  }
}

@media (min-width: 576px) {
  .bg-image-hero-bg {
    width: 838px;
    height: 600px;
    right: -460px;
    top: 0;
    bottom: auto;
  }
}

@media (min-width: 771px) {
  .bg-image-hero-bg {
    right: -410px;
    top: -60px;
  }
}

@media (min-width: 992px) {
  .bg-image-hero-bg {
    right: -340px;
    top: -160px;
    transform: rotateZ(0);
  }
}

@media (min-width: 1200px) {
  .bg-image-hero-bg {
    top: -160px;
    right: -280px;
  }
}

.hero-img {
  position: absolute;
  bottom: -140px;
  right: -80px;
  width: 200px;
}

@media (min-width: 370px) {
  .hero-img {
    bottom: -180px;
    right: -100px;
    width: 250px;
  }
}

@media (min-width: 576px) {
  .hero-img {
    bottom: auto;
    top: 110px;
    right: 0px;
    width: 240px;
  }
}

@media (min-width: 771px) {
  .hero-img {
    position: relative;
    margin-left: auto;
    top: 20px;
    right: -30px;
    width: 100%;
  }
}

@media (min-width: 992px) {
  .hero-img {
    top: 0;
    right: -30px;
    width: 90%;
  }
}

@media (min-width: 1200px) {
  .hero-img {
    width: auto;
    top: 0;
    right: -50px;
  }
}

@media (min-width: 1440px) {
  .hero-img {
    top: 0;
    right: -30px;
  }
}

.week-day-item {
  display: flex;
  align-items: center;
  margin: 0 -8px 10px;
}

@media (min-width: 576px) {
  .week-day-item {
    margin: 0 -15px 10px;
  }
}

@media (min-width: 772px) and (max-width: 992px) {
  .week-day-item {
    margin: 0 -8px 10px;
  }
}

.week-day-cell {
  padding: 0 8px;
}

@media (min-width: 576px) {
  .week-day-cell {
    padding: 0 15px;
  }
}

@media (min-width: 772px) and (max-width: 992px) {
  .week-day-cell {
    padding: 0 8px;
  }
}

.week-day {
  width: 14%;
  max-width: 14%;
  flex: 0 0 14%;
  color: #424242;
  font-size: 18px;
  font-size: 1.125rem;
  font-weight: 700;
  line-height: 1.2;
  text-transform: uppercase;
}

@media (min-width: 576px) {
  .week-day {
    font-size: 20px;
    font-size: 1.25rem;
  }
}

@media (min-width: 771px) {
  .week-day {
    width: 20%;
    max-width: 20%;
    flex: 0 0 20%;
  }
}

.week-day-cell-hours {
  width: 43%;
  max-width: 43%;
  flex: 0 0 43%;
}

@media (min-width: 771px) {
  .week-day-cell-hours {
    width: 40%;
    max-width: 40%;
    flex: 0 0 40%;
  }
}

.week-hours {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  height: 50px;
  border-radius: 10px;
  padding: 0 10px 5px;
  background-color: #f5f7ff;
  color: #8f8f8f;
  font-size: 18px;
  font-size: 1.125rem;
  font-weight: 300;
  line-height: 1;
  text-transform: uppercase;
}

@media (min-width: 576px) {
  .week-hours {
    height: 60px;
    font-size: 20px;
    font-size: 1.25rem;
  }
}
