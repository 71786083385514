
.ico-svg{
	display: inline-block;
	width: 1em;
	height: 1em;

	stroke-width: 0;
	stroke: currentColor;
	fill: currentColor;
}
