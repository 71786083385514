/* Row helpers */

.row-40 {
	margin-right: -40px;
	margin-left: -40px;
	.row-item{
		padding-left: 40px;
		padding-right: 40px;
	}
}

.row-30 {
	margin-right: -30px;
	margin-left: -30px;
	.row-item{
		padding-left: 30px;
		padding-right: 30px;
	}
}

.row-20 {
	margin-right: -20px;
	margin-left: -20px;
	.row-item{
		padding-left: 20px;
		padding-right: 20px;
	}
}

.row-10 {
	margin-right: -10px;
	margin-left: -10px;
	.row-item{
		padding-left: 10px;
		padding-right: 10px;
	}
}
