/* Image helpers */

.img {
    display: block;   
}

.img-full{
    width: 100%;
}

.img-responsive{
	display: block;
	max-width: 100%;
	height: auto;
}

.img-cover{
	height: 100%;
	object-fit: cover;
}

.img-contain{
	height: 100%;
	object-fit: contain;
}

.img-filter-group{
	// background-color: #dce1f2;
}

.img-filter-hover{
	position: relative;
	// filter:grayscale(100%);
	// &:hover{
	// 	filter:grayscale(0);
	// }
	.img-filter-classic{
		position: absolute;
		top: 50%;
		transform: translateY(-50%);
		opacity: 0;
		visibility: hidden;
		// height: 0;
		// width: 0;
		transition: all 250ms ease-out;
	}
	.img-filter-gray{
		transition: all 250ms ease-out;
	}
	&:hover{
		.img-filter-classic{
			opacity: 1;
			visibility: visible;
			// height: auto;
			// width: auto;
		}
		.img-filter-gray{
			opacity: 0;
			visibility: hidden;
			// height: 0;
			// width: 0;
		}
	}
}

.img-hover{
	display: block;
	overflow: hidden;
	&:hover{
		> img{
			transform: scale(1.05, 1.05);
		}
	}
	img{
		transition: transform 400ms ease-out;
	}
}

.bg-image-hover{
	display: block;
	overflow: hidden;
	&:hover{
		> .bg-image{
			transform: scale(1.05, 1.05);
		}
	}
	.bg-image{
		transition: transform 400ms ease-out;
	}
}

.img-flex-cover{
	display: flex;
	img{
		display: block;
		width: 100%;
		height: 100%;
		object-fit: cover;
	}
}

.img-flex-contain{
	display: flex;
	img{
		display: block;
		width: 100%;
		height: 100%;
		object-fit: contain;
	}
}

.img-flex-blog{
	height: 230px;
	@include bp(xsmall){
		height: 230px;
	}
}

.bg-image{
	display: block;
	width: 100%;
	height: 100%;
	position: absolute;
	top: 0;
	left: 0;
	background-repeat: no-repeat;
	background-position: center;
	background-size: cover;
}

.bg-image-pattern-1{
	position: absolute;
	top: 0;
	left: -550px;
	width: 912px;
	height: 1169px;
	transition: left 450ms ease-out;
	opacity: 0.6;
	@include bp(small){
		opacity: 1;
		left: -400px;
	}
}

.bg-image-pattern-2{
	position: absolute;
	top: auto;
	bottom: -600px;
	left: auto;
	right: -700px;
	width: 796px;
	height: 819px;
	transition: right 450ms ease-out;
	opacity: 0.6;
	@include bp(small){
		opacity: 1;
		bottom: -380px;
		right: -550px;
	}
	@include bp(medium){
		right: -400px;
	}
}