/* Footer */

.footer{
	position: relative;
	z-index: 2;
	padding: 3rem 0 1rem;

	-webkit-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    @include bp(xsmall){
		padding: 3rem 0 3rem;
    }
}

.contact-list{
	@include bp(xsmall){
		width: 50%;
	}
	@include bp(rev-xsmall){
		margin: 0 auto;
		max-width: 250px;
		left: 10px;
		position: relative;
	}
}

.contact-list-small{
	@include bp(rev-medium){
		margin: 2rem auto 0;
		max-width: 230px;
		width: 100%;
		left: 15px;
		position: relative;
	}
	.contact-list-link-icon{
		position: relative;
	    top: 2px;
		svg{
			@include font-size(18px);
		}
	}

	.contact-list-link-text{
		padding-left: 1rem;
		@include font-size(16px);
		min-width: 180px;
	}
}

.contact-list-item{
	display: block;
	margin-bottom: 1.2rem;
	@include bp(xsmall){
		&:last-child{
			margin-bottom: 0;
		}
	}
}

.contact-list-item-ext{
	.contact-list-link-text{
		position: relative;
		line-height: 1.8;
		top: -3px;
	}
}

.contact-list-link{
	// align-items: center;
	display: inline-block;
    vertical-align: top;
	@include bp(small){
		display: inline-flex;
	}
	&:hover{
		.contact-list-link-text{
			color: $color-blue-normal;
		}
	}
}

.contact-list-link-icon{
	position: relative;
    top: 2px;
    display: inline-block;
    vertical-align: top;
	@include bp(small){
		width: 30px;
		max-width: 30px;
		flex: 0 0 30px;
	}
	svg{
		fill: $color-blue-normal;
		@include font-size(20px);
		@include bp(small){
			@include font-size(24px);
		}
		@include bp(medium){
			@include font-size(24px);
		}
		@include bp(large){
			@include font-size(28px);
		}
	}
}

.contact-list-link-text{
	padding-left: 1rem;
	// width: calc(100% - 30px);
	// max-width: calc(100% - 30px);
	color: $color-gray-normal;
	font-weight: 300;
	line-height: 1.4;
	text-transform: uppercase;
	letter-spacing: 0.12px;
	@include font-size(18px);
	display: inline-block;
	vertical-align: top;
	@include bp(small){
		flex: 0 0 calc(100% - 30px);
		padding-left: 1rem;
		@include font-size(20px);
	}
	@include bp(medium){
		@include font-size(20px);
	}
	@include bp(large){
		@include font-size(24px);
	}
}