.week-day-item{
	display: flex;
	align-items: center;
	margin: 0 -8px 10px;
	@include bp(xsmall){
		margin: 0 -15px 10px;
	}
	@include bp(excl-medium){
		margin: 0 -8px 10px;
	}
}

.week-day-cell{
	padding: 0 8px;
	@include bp(xsmall){
		padding: 0 15px;
	}
	@include bp(excl-medium){
		padding: 0 8px;
	}
}

.week-day{
	width: 14%;
	max-width: 14%;
	flex: 0 0 14%;
	color: $color-black-normal;
	@include font-size(18px);
	font-weight: 700;
	line-height: 1.2;
	text-transform: uppercase;
	@include bp(xsmall){
		@include font-size(20px);
	}
	@include bp(small){
		width: 20%;
		max-width: 20%;
		flex: 0 0 20%;
	}
}

.week-day-cell-hours{
	width: 43%;
	max-width: 43%;
	flex: 0 0 43%;
	@include bp(small){
		width: 40%;
		max-width: 40%;
		flex: 0 0 40%;
	}
}

.week-hours{
	display: flex;
	align-items: center;
	justify-content: center;
	text-align: center;
	height: 50px;
	border-radius: 10px;
	padding: 0 10px 5px;
	background-color: $color-gray-light;
	color: $color-gray-normal;
	@include font-size(18px);
	font-weight: 300;
	line-height: 1;
	text-transform: uppercase;
	@include bp(xsmall){
		height: 60px;
		@include font-size(20px);
	}
}