/* Header */

$main-bg: #1BBC9B;
$nav-color: $color-blue-normal;
$nav-bg-active: #ffffff;

.navigation{
	.navigation-trigger{
		display: none;
	}
	@include bp(rev-medium){
		.container-normal, .container-large{
			width: 100%;
		}

		.nav-collapse{
			position: fixed;
			z-index: 98;
			top: 60px;
			left: 0;
			overflow: auto;
			width: 100%;
			height: 100%;
			padding: 25px 25px 70px;
			transform: translateX(-100%);
			background-color: $nav-bg-active;

			@include transition(none);
		}

		.navigation-trigger{
			position: fixed;
			z-index: 200;
			top: 0;
			right: 0;
			display: block;
			width: 70px;
			height: 60px;
		}

		.navigation-trigger-icon{
			position: absolute;
			top: 50%;
			left: 50%;
			display: inline-block;
			width: 30px;
			height: 3px;
			transition-duration: 250ms;
			transition-property: background-color, transform;
			transform: translate(-50%, -50%);
			background-color: $nav-color;
			font-weight: 300;

			&:before, &:after{
				position: absolute;
				display: block;
				width: 30px;
				height: 3px;
				content: '';
				transition-duration: 250ms;
				transition-property: margin, transform;
				background: $nav-color;
			}

			&:before{
				margin-top: -10px;
			}

			&:after{
				margin-top: 10px;
			}

		}

		&.is-collapse .nav-collapse{
			transform: translateX(0);

			@include transition(transform, 250ms, ease);
		}

		&.is-collapse .navigation-trigger-icon{
			transition: none;
			background: rgba(0, 0, 0, 0.0);

			&:before{
				margin-top: 0;
				transform: rotate(45deg);
			}

			&:after{
				margin-top: 0;
				transform: rotate(-45deg);
			}

		}

	}

}

.header{
}

.navigation{
	position: fixed;
	z-index: 999;
	top: 0;
	left: 0;
	width: 100%;
	padding: 0 10px;
	transition: box-shadow 400ms ease-out;
	@include bp(medium){
		padding: 0 30px;
		background-color: #ffffff;
		// box-shadow: 0 5px 10px rgba(1, 1, 1, 0.1);
	}

	@include bp(rev-medium){
		background-color: #ffffff;
		box-shadow: 0 5px 10px rgba(1, 1, 1, 0.1);
	}
}

.page-index{
	@include bp(medium){
		.header-logo{
			height: 150px;
		}
	}
	.navigation:not(.navigation-scroll){
		@include bp(medium){
			background-color: transparent;
			box-shadow: none;
		}
		@include bp(medium){
			.menu-list-link{
				color: #ffffff;
				&:after{
					background-color: #ffffff;
				}
			}
		}
	}
}

.navigation-scroll{
	background-color: #ffffff;
	box-shadow: 0 5px 10px rgba(1, 1, 1, 0.1);
	@include bp(medium){
		.header-logo{
			// padding-top: 12px;
			// padding-bottom: 12px;
			height: 70px;
		}
		// .menu-list-link{
		// 	color: $color-blue-normal;
		// 	&:after{
		// 		background-color: $color-blue-normal;
		// 	}
		// }
	}
}

.header-flex{
	@include bp(medium){
		display: flex;
		// align-items: center;
		justify-content: space-between;
	}

}

.page-index{
}

.header-logo{
	float: left;
	padding: 10px 0 10px;
	text-align: center;
	transition: height 400ms ease-out;
	display: flex;
	align-items: center;
	height: 70px;

	@include bp(rev-medium){
		height: auto;
		position: relative;
		z-index: 99;
		padding: 12px 0 12px;
	}

}

.header-logo-link{
	display: inline-block;
	vertical-align: middle;
}

.header-image{
	margin: 0 auto;
	height: 36px;

	@include bp(rev-medium){
		width: 220px;
	}
	

}

.nav-collapse{
	@include bp(medium){
		display: flex;
		// align-items: center;
	}

}

.menu-list{
	text-align: center;

	@include bp(medium){
		display: flex;
		align-items: center;
	}
	@include bp(excl-large){
		margin-right: -20px;
	}

}

.menu-list-item{
	@include bp(medium){
		display: flex;
		margin-left: 3rem;
		// align-items: center;
		&:first-child{
			margin-left: 0;
		}
	}
	@include bp(excl-large){
		margin-left: 2.5rem;
	}
	@include bp(rev-medium){
		display: block;
		margin-bottom: 0.8rem;
	}

	&.is-active{
		.menu-list-link{
			&:after{
				width: 100%;
			}
		}

	}

}

.menu-list-link{
	position: relative;
	display: inline-block;
	padding: 0.7rem 0;
	text-decoration: none;
	color: $color-blue-normal;
	line-height: 1.2;
	text-transform: uppercase;
	letter-spacing: 0.45px;
	font-weight: 700;

	@include font-size(18px);
	@include bp(medium){
		display: flex;
		align-items: center;
		color: $color-blue-normal;
		// color: #ffffff;
		@include font-size(15px);
		padding: 0.5rem 0;
	}

	&:hover{
		&:after{
			width: 100%;
		}
	}

	&:after{
		position: absolute;
		bottom: 0;
		left: 0;
		display: block;
		width: 0;
		height: 2px;
		content: '';
		transition: width 250ms ease-out;
		background-color: $color-blue-normal;
		@include bp(medium){
			background-color: $color-blue-normal;
		}
	}

}

