//====================================================
//  Media queries 
//====================================================
@mixin bp($point) {
    $xxlarge: $screen-xxl-desktop;
    $xlarge: $screen-xl-desktop;
    $large: $screen-lg-desktop;
    $medium: $screen-desktop;
    $small: $screen-tablet;
    $xsmall: $screen-phone;
    $xssmall: $screen-xs-phone;
    
    @if $point == xxlarge {
        @media (min-width: $xxlarge) { @content; }
    }
    @if $point == xlarge {
        @media (min-width: $xlarge) { @content; }
    }
    @if $point == large {
        @media (min-width: $large) { @content; }
    }
    @else if $point == medium {
        @media (min-width: $medium) { @content; }
    }
    @else if $point == small {
        @media (min-width: $small)  { @content; }
    }
    @else if $point == xsmall {
        @media (min-width: $xsmall) { @content; }
    }
    @else if $point == xssmall {
        @media (min-width: $xssmall) { @content; }
    }
    
    @else if $point == rev-xxlarge {
        @media (max-width: $xxlarge - 1) { @content; }
    }
    @else if $point == rev-xlarge {
        @media (max-width: $xlarge - 1) { @content; }
    }
    @else if $point == rev-large {
        @media (max-width: $large - 1) { @content; }
    }
    @else if $point == rev-medium {
        @media (max-width: $medium - 1) { @content; }
    }
    @else if $point == rev-small {
        @media (max-width: $small - 1)  { @content; }
    }
    @else if $point == rev-xsmall {
        @media (max-width: $xsmall - 1)  { @content; }
    }
    @else if $point == rev-xssmall {
        @media (max-width: $xssmall - 1)  { @content; }
    }
    
    @else if $point == excl-xssmall {
        @media (max-width: $xssmall) { @content; }
    }
    @else if $point == excl-xsmall {
        @media (max-width: $xsmall) { @content; }
    }
    @else if $point == excl-small {
        @media (min-width: $xsmall + 1) and (max-width: $small) { @content; }
    }
    @else if $point == excl-medium {
        @media (min-width: $small + 1) and (max-width: $medium) { @content; }
    }
    @else if $point == excl-large {
        @media (min-width: $medium + 1) and (max-width: $large) { @content; }
    }
    @else if $point == excl-xlarge {
        @media (min-width: $large + 1) and (max-width: $xlarge) { @content; }
    }
    @else if $point == excl-xxlarge {
        @media (min-width: $xlarge + 1) and (max-width: $xxlarge) { @content; }
    }
}

//====================================================
//  Row machine
//====================================================
@mixin rowMachine($numPerRow, $margin, $marginBottom) {
  width: ((100% - (($numPerRow - 1) * $margin)) / $numPerRow);
  &:nth-child(n) {
    margin-bottom: $marginBottom;
    margin-right: $margin;
  }
  &:nth-child(#{$numPerRow}n) {
    margin-right: 0;
    margin-bottom: $marginBottom;
  }
}

//====================================================
//  Font size
//====================================================

// Function for converting a px based font-size to rem.
@function calculate-rem($size) {
  $remSize: $size / 16px;
//Default font size on html element is 100%, equivalent to 16px;
  @return #{$remSize}rem;
}

// Mixin that will include the fall back px declaration as well as the calculated rem value.
@mixin font-size($size) {
  font-size: $size;
  font-size: calculate-rem($size);
}


//====================================================
//  Font family
//====================================================

@mixin Font1 {
    font-family: $font-primary;
}

@mixin Font2 {
    font-family: $font-primary;
}


//====================================================
//  Cross browser opacity
//====================================================
@mixin opacity($opacity) {
	opacity: $opacity;
	$opacity-ie: $opacity * 100;
	filter: alpha(opacity=$opacity-ie);
}

@mixin transition($transition-property: $transition-property, $transition-duration: $transition-duration, $transition-animation: $transition-easing ) {
	transition: $transition-property $transition-duration $transition-animation;
}

@mixin transform($transform) {
	transform: $transform;
}

@mixin border-radius($border-radius) {
	border-radius: $border-radius;
}

@mixin box-shadow($box-shadow) {
	box-shadow: $box-shadow;
}


